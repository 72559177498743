import axios from 'axios'
import decode from 'jwt-decode'

import {getAuthentication, removerChavePagseguro, removeSecurityToken, saveSecurityToken} from '../seguranca/securityContext'


import {SLOW_REQUEST_CONFIG} from '../util/loadingUtil'
import {URL} from '../util/ambienteActions';
import {
    AUTHENTICATION_FAILED,
    CADASTRAR_CLIENTE,
    USER_AUTHENTICATED,
    USER_LOGOUT,
    TROCAR_SENHA_USUARIO,
    BUSCAR_MEUS_DADOS, ALTERAR_DADOS_CLIENTE, EXCLUIR_CLIENTE,
} from '../reducers/usuariosReducer';

const authenticationUrl = `${URL}/auth/cliente/login`;

export function validarToken(token) {
    const dataToken = decode(token).exp;
    // eslint-disable-next-line radix
    const dataCorrente = parseInt(Date.now() / 1000);
    return dataToken > dataCorrente
}

export function isUserAuthenticated(auth) {
    return auth && auth.isAuthenticated && auth.tokenJwt && validarToken(auth.tokenJwt)
}

export const authenticateUser = data => dispatch => axios.post(authenticationUrl, data, SLOW_REQUEST_CONFIG)
    .then((response) => {
        const usuarioAutenticado = response.data;

        const tokenDecodificado = decode(usuarioAutenticado.tokenServico.tokenJwt);
        if (!validarToken(usuarioAutenticado.tokenServico.tokenJwt)) {
            dispatch({
                type: AUTHENTICATION_FAILED,
                payload: {erro: 'Sessão Expirou'},
            })
        }
        const authentication = {
            tokenJwt: usuarioAutenticado.tokenServico.tokenJwt,
            username: data.username,
            nomeUsuario: tokenDecodificado.nomeUsuario,
            idUsuario: tokenDecodificado.codigoUsuario,
            isAuthenticated: true,
        };
        saveSecurityToken(authentication);

        return dispatch({
            type: USER_AUTHENTICATED,
            payload: authentication,
        })
    })
    .catch(err => dispatch({
        type: AUTHENTICATION_FAILED,
        payload: {err},
    }));

export const logoutUser = () => {
    removeSecurityToken();
    removerChavePagseguro();

    return {
        type: USER_LOGOUT,
        payload: {
            tokenJwt: null,
            isAuthenticated: false,
            username: null,
        },
    }
};


export function cadastrarCliente(values) {
    const novoUsuario = {
        nome: values.nome.trim(),
        email: values.email,
        senha: values.password,
        telefone: values.telefone,
        cpf: values.cpf,
    };

    const url = `${URL}/cliente/public/cadastrar`;

    return dispatch => axios.post(url, novoUsuario, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: CADASTRAR_CLIENTE,
            payload: response,
        }),
    );

}

export function excluirCliente() {
    const url = `${URL}/cliente/excluir`;

    return dispatch => axios.post(url, null, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: EXCLUIR_CLIENTE,
            payload: response,
        }),
    );

}

export function trocarSenhaUsuario(email, codigo, senha) {
    const url = `${URL}/usuarios/public/trocar-senha`;
    return dispatch => axios.post(url, {email, codigo, senha}, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: TROCAR_SENHA_USUARIO,
            payload: response,
        }),
    );
}

export function buscarDadosUsuarioLogado() {
    const url = `${URL}/cliente/meus-dados`;
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: BUSCAR_MEUS_DADOS,
            payload: response.data,
        }),
    )
}

export const alterarDadosCliente = values => dispatch => axios.post(`${URL}/cliente/atualizar-dados`, {
    nome: values.nome,
    telefone: values.telefone,
    cpf: values.cpf,
}, SLOW_REQUEST_CONFIG).then(() => {
    const autenticacao = {...getAuthentication(), nomeUsuario: values.nome}
    saveSecurityToken(autenticacao);
    return dispatch({
        type: ALTERAR_DADOS_CLIENTE,
        payload: autenticacao,
    })
});

import React from 'react'

const ConteudoQuemSomosTuri = () => (
  <div className="page-contato" style={{ fontSize: 16, backgroundColor: '#ffffff' }}>
    <section className="gv-section">
      <div className="section-content">
        <h2 className="gv-title"> <b>NOSSA HISTÓRIA</b></h2>        
        <p>A Catarinense Turismo – Turissul, iniciou suas atividades em 19/07/2000, no setor de viagens de compras. Com o passar do tempo foi aprimorando seus serviços no ramo viagens de turismo, turismo religioso, eventos, traslados, receptivo entre outros. Em 2005, os investimentos aumentaram com a aquisição de novos veículos e com trabalhos especializados.​</p>
        <p>A Catarinense Turismo continuará se dedicando a contribuir com o desenvolvimento do turismo, buscando sempre prestar serviços de qualidade refletida em veículos bem cuidados, pontualidade, segurança e com profissionais capacitados para exercer a função. Não perdemos de vista as dificuldades, porém temos confiança para enfrentarmos os desafios.</p>
        <p>A história dessa empresa é marcada por batalhas e conquistas. Mas o principal motivo desses resultados é o fato de persistência, trabalho duro, fé e carinho por nossos clientes.</p>
      </div>
    </section>
  </div>
)

export default ConteudoQuemSomosTuri

import React from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  maxValueTelefone,
  minDocumento,
  renderFieldCPF,
  renderFieldNomePassageiro,
  renderFieldNumeroDocumento,
  renderFieldTelefonePassageiro,
  renderFieldText,
  required,
  requiredCPF,
  apenasLetra,
  maxTamanhoNome
} from '../../util/inputs';
import iconPoltrona from '../../estilo/comum/img/poltrona.png'
import { mascaraNumero } from '../../util/arraysUtil';
import { getBrowserHistory, isAmbienteProd } from '../../util/applicationContext';
import {getChavePagseguro, getVendaPendente, saveVendaPendenteLocalStorage} from '../../seguranca/securityContext';
import {
  adicionarPassageiros,
  alterarValorTotalPassagens,
  aplicarCupomDesconto,  
  recuperarMeioPagamento,
  recuperarOpcoesPagamento
} from '../../actions/vendasActions';
import {showAlertMsg} from '../../util/messagesUtil';
import { recuperarBandeiraCartao, validateCard } from '../../util/cartaoUtil';
import PagamentoPagSeguro from '../comum/PagamentoPagSeguro';
import safe from '../../estilo/comum/img/safe.svg';
import google from '../../estilo/comum/img/google.svg';
import ReactTooltip from 'react-tooltip';
import { EMPRESAS_QUE_NAO_APLICAM_CUPOM } from '../../util/constantes';

export const validate = (values) => {
  const errors = {};
  if (!values.nome) {
    errors.nome = 'Campo obrigatório'
  }
  if (!values.email) {
    errors.email = 'Campo obrigatório'

  }

  return errors
};

export class Passageiro extends React.Component {
  constructor(props) {
    super(props);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.state = {
      cupomAplicado: false,
      parcelas: [],
      bandeira: '',
      meioPagamento: 'PAG_SEGURO',
      senderHash: '',
      formaPagamento: 'Cartão',
      bloqueioPoltronaExpirado: false
    }
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.recuperarMeioPagamento().then(resp => this.setState({ meioPagamento: resp.payload }))    
    const vendaPendente = getVendaPendente();
    // eslint-disable-next-line no-undef
    const { venda } = this.props;
    if (vendaPendente) {
      this.props.alterarValorTotalPassagens(venda.valorCupomDesconto);
      let passageiros = Object.assign([], venda.passageiros);
      vendaPendente.reservas.map(reserva => {
        const jaExiste = passageiros.filter(f => f.poltronaId === reserva.passageiroDto.poltronaId).length > 0;
        if (!jaExiste) {
          passageiros.push(reserva.passageiroDto)
        }
      });
      this.props.adicionarPassageiros(passageiros);
      passageiros.map(passageiro => {
        this.props.changeFieldValue('formReserva', `cpfPassageiro${passageiro.poltronaId}`, passageiro.cpfPassageiro);
        this.props.changeFieldValue('formReserva', `nomePassageiro${passageiro.poltronaId}`, passageiro.nome);
        this.props.changeFieldValue('formReserva', `telefonePassageiro${passageiro.poltronaId}`, passageiro.telefone);
        this.props.changeFieldValue('formReserva', `documentoPassageiro${passageiro.poltronaId}`, passageiro.documentoComFoto);

      });
      if(vendaPendente.dadosCompradorDto.cpfComprador){
        this.props.changeFieldValue('formReserva', 'numeroEnderecoComprador', vendaPendente.dadosCompradorDto.endereco.numero);
        this.props.changeFieldValue('formReserva', 'complementoEnderecoComprador', vendaPendente.dadosCompradorDto.endereco.complemento);
        this.props.changeFieldValue('formReserva', 'telefoneComprador', vendaPendente.dadosCompradorDto.telefoneComprador);
        this.props.changeFieldValue('formReserva', 'cepComprador', vendaPendente.dadosCompradorDto.endereco.cep);
        this.props.changeFieldValue('formReserva', 'cpfComprador', vendaPendente.dadosCompradorDto.cpfComprador);
        this.props.changeFieldValue('formReserva', 'dataNascimentoComprador', vendaPendente.dadosCompradorDto.dataNascimentoComprador);
        this.props.changeFieldValue('formReserva', 'nomeTitularCartaoCredito', vendaPendente.dadosCompradorDto.nomeComprador);

      }
    }
    this.setState({ cupomAplicado: false });
    this.props.alterarValorTotalPassagens(0.00);
    this.props.changeFieldValue('formReserva', 'cupomDesconto', '');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.venda.valorTotalGeral != this.props.venda.valorTotalGeral) {
        let numeroCartao = this.props.numeroCartaoCredito;
      // eslint-disable-next-line no-undef
      if (_.size(numeroCartao) > 0 ) {
        numeroCartao = numeroCartao.replace(/\s/g, '')
        if (validateCard(numeroCartao) || this.ehAmbienteTeste(numeroCartao)) {
          this.buscarInformacoesPagamentoPagSeguro(numeroCartao, this.props.venda.valorTotalGeral);
        }
      }
    }
  }

  buscarParcelas(e) {
    const numeroCartao = e.target.value.replaceAll(' ', '')
    const { valorTotalGeral } = this.props.venda;
    if (validateCard(numeroCartao) || this.ehAmbienteTeste(numeroCartao)) {
      this.props.changeFieldValue('formReserva', 'numeroCartaoCredito', numeroCartao);
      const bandeiraCartao = recuperarBandeiraCartao(numeroCartao);
      if(bandeiraCartao.length > 1) {
        this.buscarInformacoesPagamentoPagSeguro(numeroCartao, valorTotalGeral, bandeiraCartao, this.props);
      } else {
        showAlertMsg('Cartão inválido. Confira o numero do seu cartão de crédito!!')
      }
    }

  }

  ehAmbienteTeste(numeroCartao) {
    return !isAmbienteProd() && numeroCartao.length === 16
  }

  parserValor(valor) {
    return valor.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  buscarInformacoesPagamentoPagSeguro(numeroCartao, valorTotalGeral) {
    let parcelasPagamento = [];
    this.setState({ parcelas: [] });
    this.props.changeFieldValue('formReserva', 'numeroCartaoCredito', numeroCartao);
    if (validateCard(numeroCartao) || this.ehAmbienteTeste(numeroCartao)) {
      const bandeiraCartao = recuperarBandeiraCartao(numeroCartao)
      this.setState({ bandeira: bandeiraCartao })
      this.props.recuperarOpcoesPagamento(valorTotalGeral, bandeiraCartao, numeroCartao.substr(0, 6)).then(resp => {
        if (resp) {
          resp.payload.map(p => {
            parcelasPagamento.push({
              parcela: `${p.quantity} parcela(s) de ${this.parserValor(p.amount)} total de (${this.parserValor(p.totalAmount)}).`,
              valorTotal: p.totalAmount,
              qtdParcelas: p.quantity,
              valorParcela: p.amount,
              metodoPagamento: 'cartao',
            })
          })
        }
        this.preencherESelecionarPrimeiraOpcaoPagamento(parcelasPagamento, numeroCartao)
      }).catch(erro => {
        console.log(erro);
        showAlertMsg('Cartão inválido. Confira o numero do seu cartão de crédito!!')
      })
    }

  }

  preencherESelecionarPrimeiraOpcaoPagamento(parcelasPagamento, numeroCartao) {
    const parcela = {
      label: parcelasPagamento[0].parcela,
      value: parcelasPagamento[0].qtdParcelas,
      valorTotal: parcelasPagamento[0].valorTotal,
      qtdParcelas: parcelasPagamento[0].qtdParcelas,
      metodoPagamento: parcelasPagamento[0].metodoPagamento,
    };
    this.props.changeFieldValue('formReserva', 'parcelas', parcela);
    this.setState({ parcelas: parcelasPagamento, numeroCartao })

  }

  limparMensagem() {
    this.setState({ mensagem: undefined })
  }

  limparFormulario() {
    this.props.reset('formReserva');
    this.limparMensagem();
  }


  preencherNomePassageiro(texto, { poltronaId }) {
    const { passageiros } = this.props.venda;
    const passageiroCorrente = passageiros.find(f => f.poltronaId === poltronaId);
    const indice = passageiros.indexOf(passageiroCorrente);
    const passageiroNovo = Object.assign({}, passageiroCorrente, { nome: texto });
    const copiaListaPassageiros = Object.assign([], passageiros);
    copiaListaPassageiros[indice] = passageiroNovo;
    this.props.adicionarPassageiros(copiaListaPassageiros)
  }

  preencherCpfPassageiro(texto, { poltronaId }) {
    const { passageiros } = this.props.venda;
    const passageiroCorrente = passageiros.find(f => f.poltronaId === poltronaId);
    const indice = passageiros.indexOf(passageiroCorrente);
    const passageiroNovo = Object.assign({}, passageiroCorrente, { cpfPassageiro: texto });
    const copiaListaPassageiros = Object.assign([], passageiros);
    copiaListaPassageiros[indice] = passageiroNovo;
    this.props.adicionarPassageiros(copiaListaPassageiros)
  }


  preencherNumeroDocumentoPassageiro(texto, { poltronaId }) {
    const { passageiros } = this.props.venda;
    const passageiroCorrente = passageiros.find(f => f.poltronaId === poltronaId);
    const indice = passageiros.indexOf(passageiroCorrente);
    const passageiroNovo = Object.assign({}, passageiroCorrente, { documentoComFoto: texto });
    const copiaListaPassageiros = Object.assign([], passageiros);
    copiaListaPassageiros[indice] = passageiroNovo;
    this.props.adicionarPassageiros(copiaListaPassageiros)
  }

  preencherTelefonePassageiro(texto, { poltronaId }) {
    const { passageiros } = this.props.venda;
    const passageiroCorrente = passageiros.find(f => f.poltronaId === poltronaId);
    const indice = passageiros.indexOf(passageiroCorrente);
    const passageiroNovo = Object.assign({}, passageiroCorrente, { telefone: texto });
    const copiaListaPassageiros = Object.assign([], passageiros);
    copiaListaPassageiros[indice] = passageiroNovo;
    this.props.adicionarPassageiros(copiaListaPassageiros)
  }

  montarPassageiros(reservas) {
    const { cupomAplicado } = this.state;
    const vendaPendente = getVendaPendente();
    let ida = null;
    let retorno = null;
    if (vendaPendente) {
      ida = vendaPendente.reservas.filter(f => f.sentido === 'IDA')[0];
      retorno = vendaPendente.reservas.filter(f => f.sentido === 'RETORNO')[0]
    }
    const {
      passageiros,
      itinerarioIda,
      itinerarioRetorno,
      destino,
      origem,
      bloqueios,
    } = this.props.venda;
    passageiros.filter(f => f.sentido === 'IDA').forEach(pass => {
      reservas.push(
        {
          idReserva: null,
          idEmpresa: ida ? ida.idEmpresa : itinerarioIda.idEmpresa,
          passageiroDto: pass,
          poltronaId: pass.poltronaId,
          sentido: 'IDA',
          ehVendaApp: true,
          itinerarioId: ida ? ida.itinerarioId : itinerarioIda.idItinerario,
          desconto: 0,
          idCidadeOrigem: vendaPendente ? vendaPendente.cidadeOrigem : origem.id,
          idCidadeDestino: vendaPendente ? vendaPendente.cidadeDestino : destino.id,
          valor: pass.preco,
          embarque: pass.embarque,
          cupomDesconto: cupomAplicado ? this.props.cupomDesconto : null,
          garantiaPrecoUuid: this.retornarGarantiaPrecoUuidPorBloqueioPoltronasTemporario(bloqueios, pass.poltronaId),
          dataHoraPartida: ida ? ida.dataHoraPartida : itinerarioIda.dataHoraPartida,
          dataHoraChegada: ida ? ida.dataHoraChegada : itinerarioIda.dataHoraChegada

        }
      );
    });

    passageiros.filter(f => f.sentido === 'RETORNO').forEach(pass => {
      reservas.push(
        {
          idReserva: null,
          idEmpresa: retorno ? retorno.idEmpresa : itinerarioRetorno.idEmpresa,
          passageiroDto: pass,
          ehVendaApp: true,
          poltronaId: pass.poltronaId,
          sentido: 'RETORNO',
          itinerarioId: retorno ? retorno.itinerarioId : itinerarioRetorno.idItinerario,
          desconto: 0,
          idCidadeOrigem: vendaPendente ? vendaPendente.cidadeDestino : destino.id,
          idCidadeDestino: vendaPendente ? vendaPendente.cidadeOrigem : origem.id,
          valor: pass.preco,
          embarque: pass.embarque,
          cupomDesconto: cupomAplicado ? this.props.cupomDesconto : null,
          garantiaPrecoUuid: this.retornarGarantiaPrecoUuidPorBloqueioPoltronasTemporario(bloqueios, pass.poltronaId),
          dataHoraPartida: retorno ? retorno.dataHoraPartida : itinerarioRetorno.dataHoraPartida,
          dataHoraChegada: retorno ? retorno.dataHoraChegada : itinerarioRetorno.dataHoraChegada
        }
      );
    })
  }

  retornarGarantiaPrecoUuidPorBloqueioPoltronasTemporario(bloqueios, poltronaId) {
    return bloqueios.filter(it => it.poltronaId === poltronaId).map(it => it.uuid).shift();
  }

  montarPedido(values) {
    const venda = getVendaPendente();
    const reservas = [];
    let comprador= null;
    const {
      username,
      venda: { itinerarioIda, destino, origem, valorTotalGeral }
    } = this.props;

    const formaPagamenro = {
      tipo: this.state.formaPagamento,
      codigoTipo: this.state.formaPagamento !== 'PIX' ? 'CARTAO_CREDITO' : this.state.formaPagamento,
      base64Comprovante: '',
      numeroComprovante: '',
      valor: valorTotalGeral,
      quantidadeParcelas: values.parcelas? values.parcelas.qtdParcelas : 1
    };

    if(this.state.formaPagamento !== 'PIX'){
      let chavePublica = getChavePagseguro();
      let card = null;
      let encrypted = '';

      if (chavePublica) {
        const pagSeguro = window.PagSeguro || this.props.pagSeguro;
        card = pagSeguro.encryptCard({
          publicKey: chavePublica,
          holder: values.nomeTitularCartaoCredito,
          number: mascaraNumero(values.numeroCartaoCredito),
          expMonth: values.dataValidadeCartaoCredito.substr(0, 2),
          expYear: '20'.concat(values.dataValidadeCartaoCredito.substr(3, 4)),
          securityCode: values.codigoSegurancaCartaoCredito
        });
        
        encrypted = card.encryptedCard;        
      }      
      comprador = {
      cartao: {
        binCard: mascaraNumero(values.numeroCartaoCredito).substr(0, 6),
        encrypted: card ? encrypted : '',        
      },
      cpfComprador: values.cpfComprador,
      nomeComprador: values.nomeTitularCartaoCredito,
      email: username,
      telefoneComprador: values.telefoneComprador,
      sanderHash: this.state.sanderHash,
      dataNascimentoComprador: values.dataNascimentoComprador,
      endereco: {
        cep: values.cepComprador,
        complemento: values.complementoEnderecoComprador,
        numero: values.numeroEnderecoComprador
      },
    }
    }else {
      comprador = {
        email: username
      }
    }
    this.montarPassageiros(reservas)
    const criarReserva = {
      tipoVeiculo: venda ? venda.tipoVeiculo : itinerarioIda.tipoVeiculo,
      cidadeOrigem: venda ? venda.cidadeOrigem : origem.id,
      cidadeDestino: venda ? venda.cidadeDestino : destino.id,
      empresaId: venda ? venda.empresaId : itinerarioIda.idEmpresa,
      embarque: venda ? venda.embarque : itinerarioIda.embarque,
      reservas,
      pontoVenda: 'SITE',
      formasPagamentoDto: [formaPagamenro],
      dadosCompradorDto: comprador,
      api: venda ? venda.api: itinerarioIda.api,
      nomeEmpresa: venda? venda.nomeEmpresa : itinerarioIda.codeEmpresa,
      electronicTicketAvailable: venda? venda.electronicTicketAvailable : itinerarioIda.electronicTicketAvailable
    };
    return criarReserva
  }

  // converterHoraAlemanhaParaBrasil(horaAlemanha) {
  //   // Converte a string para um objeto moment no fuso horário da Alemanha
  //   const momentoAlemanha = moment.tz(horaAlemanha, 'Europe/Berlin');
  //
  //   // Obtém a string de data e hora no fuso horário do Brasil
  //   const stringHoraBrasil = momentoAlemanha.clone().tz('America/Sao_Paulo').format();
  //   return stringHoraBrasil
  // }


  validarTempoExpiracaoPoltronas(){
    const {bloqueios, itinerarioIda} = this.props.venda;

    if(!itinerarioIda.integracao)
      return false;

    // eslint-disable-next-line no-unused-vars
    let poltrona=null
    bloqueios.forEach(bloqueio => {
      const agora = new Date();
      const expiresAtDate = new Date(bloqueio.expiresAt);
      if (expiresAtDate <= agora) {
        poltrona = bloqueio.poltronaId
      }
    });
    return poltrona
  }

  submit(values) {
    const poltrona = this.validarTempoExpiracaoPoltronas();
    if (poltrona) {
      this.setState({bloqueioPoltronaExpirado: true});
      showAlertMsg(`A poltrona ${poltrona} teve a data expirada. Volte à seleção de poltronas`);
    } else {
      const {formaPagamento} = this.state;
      const url = `/recibo-pagamento?formaPagamento=${formaPagamento}`;
      const pedido = this.montarPedido(values);
      saveVendaPendenteLocalStorage(pedido);
      if (!this.props.isAuthenticated) {
        getBrowserHistory().push('/login?venda');
      } else {
        this.props.criarReserva(pedido).then(resp => {
          saveVendaPendenteLocalStorage(null);
          if (resp.payload) {
            getBrowserHistory().push(url);
          }
        })
      }
    }
  }

  handleAplicarCupomDesconto() {
    const {venda: {valorTotalPassagens}, cupomDesconto} = this.props;
    const valor = new Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 3 }).format(valorTotalPassagens)

    this.props.aplicarCupomDesconto(valor, cupomDesconto).then(resp => {
      this.props.alterarValorTotalPassagens(resp.payload);
      this.setState({cupomAplicado: true});
    })
  }

  handleRemoverCupomDesconto() {
    this.setState({ cupomAplicado: false });
    this.props.alterarValorTotalPassagens(0.00);
    this.props.changeFieldValue('formReserva', 'cupomDesconto', '');
  }

  preencherDadosPassageiro(passageiro, index) {
    const style = {
      backgroundColor: 'rgb(247 147 30)',
      borderRadius: '8px',
      padding: '8px',
      width: '35px',
      textAlign: 'center'
    };

    return (
      <div key={`passageiro-${index}`}>
        <div className="card mb-2 container titulo-item-pagamento">
          <div className="form-row">
            <span>Dados do Passageiro</span>
          </div>
        </div>
        <div className="card mb-2 container ddpassageiro" key={`passageiro${passageiro.poltronaId}`}
          style={{ padding: '10px 20px 20px !important' }}>
          <div className="form-row">
            <div className="col-sm-1 col-md-1">
              <span>Poltrona</span>
              <img id='IconPoltronaMobile' src={iconPoltrona} alt="ícone poltrona" style={{ width: '32%', margin: '7px 8px -9px 0' }} />
              <strong style={style}>{passageiro.numeroPoltrona}</strong>
            </div>
            <div className="col-md-2 col-sm-2">
              <Field name={`cpfPassageiro${passageiro.poltronaId}`}
                component={renderFieldCPF}
                label='CPF'
                passageiro={passageiro}
                preencherCpfPassageiro={this.preencherCpfPassageiro.bind(this)}
                validate={[requiredCPF]}
                css="form-control" />
            </div>
            <div className="col-md-4 col-sm-4">
              <Field name={`nomePassageiro${passageiro.poltronaId}`} type="text"
                component={renderFieldNomePassageiro}
                label='Nome completo'
                preencherNomePassageiro={this.preencherNomePassageiro.bind(this)}
                passageiro={passageiro}
                validate={[required, apenasLetra, maxTamanhoNome]}
                css="form-control" readOnly={false} />
            </div>
            <div className="col-12 col-sm-2 col-md-2">
              <Field name={`telefonePassageiro${passageiro.poltronaId}`} type="text"
                component={renderFieldTelefonePassageiro}
                preencherTelefonePassageiro={this.preencherTelefonePassageiro.bind(this)}
                css="form-control" label="Telefone"
                validate={[required, maxValueTelefone]}
                passageiro={passageiro}
              />
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              <Field name={`documentoPassageiro${passageiro.poltronaId}`} type="text" max={15}
                component={renderFieldNumeroDocumento}
                passageiro={passageiro}
                preencherNumeroDocumentoPassageiro={this.preencherNumeroDocumentoPassageiro.bind(this)}
                label='Número documento com foto'
                validate={[required, minDocumento]}
                css="form-control" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleOpcaoPagamento(formaPagamento) {
    this.setState({ formaPagamento })
  }

  renderAplicarCupomDesconto() {
    const { cupomAplicado } = this.state;
    const { cupomDesconto, venda: { itinerarioIda } } = this.props;
    const venda = getVendaPendente();
    const api = venda ? venda.api: itinerarioIda.api;
    const idEmpresa = venda ? venda.empresaId : itinerarioIda.idEmpresa;    
    const ehIntegracao = api !== 'VEXADO' || EMPRESAS_QUE_NAO_APLICAM_CUPOM.includes(idEmpresa);
    if (ehIntegracao) {
      return (
          <div className='card mt-2 mb-2 container ddcupomdesconto'>
            <div className="col-sm-5 col-md-5 col-lg-5 mb-2">
              <Field name='cupomDesconto' type="text"
                     readOnly='true'
                     component={renderFieldText}
                     placeholder='Empresa não cadastrada para uso de cupom de desconto'
                     label='Cupom de desconto'
                     css="form-control"/>
              <p className='m-3' style={{color: '#e3ae2d'}}>* Empresa não cadastrada para uso de cupom de desconto</p>
            </div>
          </div>
      )
    }


    if (cupomAplicado) {
      return ''
    }
    return (
      <div className='card mt-2 mb-2 container ddcupomdesconto'>
        <div className="col-sm-3 col-md-3 col-lg-3 mb-2">
          <Field name='cupomDesconto' type="text"
            component={renderFieldText}
            label='Cupom de desconto'
            css="form-control" />
        </div>
        {cupomDesconto ?
          <div className="col-sm-3 col-md-3 col-lg-3" style={{ marginTop: '29px' }}>
            <button type="button"
              onClick={() => this.handleAplicarCupomDesconto()}
              className="btn btn-laranja">Aplicar
            </button>
          </div>
          : ''
        }
      </div>
    )
  }

  renderRemoverCupomDesconto() {
    const { cupomAplicado } = this.state;
    const { cupomDesconto } = this.props;
    return cupomAplicado ?
      <div className="mb-2" style={{ marginTop: '29px' }}>
        <div className='titulo-item-pagamento'>
          Cupom de desconto <span
            style={{ color: '#02c659' }}>{cupomAplicado ? cupomDesconto.concat(' aplicado com sucesso') : ''}</span>
          <button type="button"
            onClick={() => this.handleRemoverCupomDesconto()}
            className="ml-3 btn btn-light">Remover
          </button>
        </div>

      </div>
      : ''
  }

  render() {
    const {
      venda: {
        valorCupomDesconto,
        valorTotalTaxaServico,
        valorTotalGeral, valorTotalTaxaEmbarque, valorTotalPassagens, passageiros
      },
      handleSubmit
    } = this.props;
    const { parcelas } = this.state;
    return (
      <div className="">
        <form onSubmit={handleSubmit(this.submit)}>
          {passageiros.map((passgeiro, index) => this.preencherDadosPassageiro(passgeiro, index))}
          <div className="mt-2 container ddresumo">
            {this.renderAplicarCupomDesconto()}
            {this.renderRemoverCupomDesconto()}
          </div>
          <PagamentoPagSeguro parcelas={parcelas}
            handleOpcaoPagamento={this.handleOpcaoPagamento.bind(this)}
            formaPagamento={this.state.formaPagamento}
            valorTotalGeral={valorTotalGeral}
            buscarParcelas={this.buscarParcelas.bind(this)} />

          <div className="card mt-2 container ddresumo">
            <div className='titulo-item-pagamento'>Resumo da compra</div>
            <div className="form-row mt-2 container-resumo">
              <div className="col ddresumo-item">
                <div className="mb-6">Valor das passagens</div>
                <br />
                <div className="valor-passagem-resumo">{valorTotalPassagens.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })}</div>
              </div>
              <div className="col ddresumo-item">
                <div className="mb-6">(+) Valor das taxas de embarque</div>
                <div className="valor-passagem-resumo">{valorTotalTaxaEmbarque.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })}</div>
              </div>
              <div className="col ddresumo-item">
                <div data-tip data-for={'tooltip-taxa'} className='taxas-servico'>
                  <div className="mb-6 taxa-servico">(+) Valor das taxas de serviço</div>
                  <span className="align-self-center tooltip-info">
                    <div className="tooltip-ut">
                      <ReactTooltip className="tooltip-ut tool-taxa" id={'tooltip-taxa'}
                                    type="dark" effect="solid">
                        <div className="row">Esta é uma taxa de conforto e comodidade de uma compra online,
                                      onde com ela garantimos os melhores serviços e atendimentos.</div>
                      </ReactTooltip>
                    </div>
                  </span>
                  <div className="valor-passagem-resumo">{valorTotalTaxaServico.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}</div>
                </div>
              </div>
              <div className="col ddresumo-item">
                <div className="mb-6">(-) Valor do cupom de descontos</div>
                <div className="valor-passagem-resumo">{valorCupomDesconto.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })}</div>
              </div>
              <div className="col ddresumo-item last">
                <div id='valorCompra' className="mb-6">Valor total da compra</div>
                <div>
                  <p className="valor-passagem-resumo final">
                    {this.props.parcelas ?
                      this.props.parcelas.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                      : valorTotalGeral.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-md-center modal-footer captcha'>
            <div className='col col-lg-3'>
              <img id='ssl' src={safe} />
            </div>
            <div className='col col-lg-3 google'>
              <img id='google' src={google} />
            </div>
          </div>
          <div className="row justify-content-center">
            {!this.state.bloqueioPoltronaExpirado?
              <button id="btn-salvar" type="submit" style={{width: 250}}
                className="btn btn-laranja btn-sistema">FINALIZAR PEDIDO
              </button>
              : ''
            }
          </div>
        </form>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    tipoDocumento: formValueSelector('formReserva')(state, 'tipoDocumento'),
    cupomDesconto: formValueSelector('formReserva')(state, 'cupomDesconto'),
    parcelas: formValueSelector('formReserva')(state, 'parcelas'),
    numeroCartaoCredito: formValueSelector('formReserva')(state, 'numeroCartaoCredito'),
    venda: state.venda,
    pagSeguro: state.pagSeguro.pagSeguro
  }
}

export default connect(mapStateToProps, {
  adicionarPassageiros,
  aplicarCupomDesconto,
  recuperarOpcoesPagamento,
  alterarValorTotalPassagens,
  recuperarMeioPagamento
})(reduxForm({
  form: 'formReserva',
  validate
})(Passageiro));

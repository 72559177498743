/* eslint-disable react/no-unknown-property */
import {connect} from 'react-redux';
import React, {Component} from 'react'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {withRouter} from 'react-router-dom';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined'

import {buscarCidadesPorNome} from '../../actions/cidadeActions'
import SelecaoCidadeAutocomplete from '../comum/SelecaoCidadeAutocomplete';
import {
    buscarPassagens,
    limparDataRetorno,
    limparPassagens,
    selecionarDataIda,
    selecionarDataRetorno,
    selecionarDestino,
    selecionarOrigem,
} from '../../actions/vendasActions';
import {recuperarConfEmpresa} from '../../util/applicationContext';


export class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sugestoes: [],
        }
    }

    selecionarOrigem(origem) {
        this.props.selecionarOrigem(origem);
    }

    selecionarDestino(destino) {
        this.props.selecionarDestino(destino);
    }

    buscar() {
        const {origem, destino} = this.props.venda
        let url = `/passagem-de-onibus/${origem.nomeComUfNormalizado}/${destino.nomeComUfNormalizado}/${moment(this.props.dataIdaSelecionada).format('YYYY-MM-DD')}`;
        if (this.props.dataRetornoSelecionada) {
            url = `${url}/${moment(this.props.dataRetornoSelecionada).format('YYYY-MM-DD')}`
        }
        this.props.history.push(url);
    }

    exibirCalendario(ida) {
        let data;

        let minDate = new Date();
        if (ida) {
            if (this.props.dataIdaSelecionada) {
                data = moment(this.props.dataIdaSelecionada).toDate()
            }
        } else {            
            if (this.props.dataRetornoSelecionada) {
                data = moment(this.props.dataRetornoSelecionada).toDate()
            }
        }
        return (
            <div aria-label="campo-busca-data" style={{width: '100%', marginBottom: 15}}>
                <i className="material-icons nav-icon mt-2 ml-2"
                   style={{position: 'absolute', zIndex: 9998}}>date_range</i>
                <DatePicker
                    className='form-control form-control-lg campo-busca-data'
                    value={data}
                    selected={data}
                    startDate={moment(this.props.dataIdaSelecionada).toDate()}
                    endDate={moment(this.props.dataRetornoSelecionada).toDate()}
                    minDate={minDate}
                    disabled={!ida && !this.props.dataIdaSelecionada}
                    onChange={date => this.selecionarData(date, ida)}
                    dateFormat="dd/MM/yyyy"
                    withPortal
                    placeholderText='Selecione a data'
                    aria-label='campo-busca-data'
                />
            </div>
        )
    }

    selecionarData(data, ida) {
        this.props.limparPassagens();
        if (ida) {
            this.props.selecionarDataIda(data);
            this.props.limparDataRetorno();
        } else {
            this.props.selecionarDataRetorno(data);
        }
    }

    limpar() {
        this.props.limparDataRetorno();
        this.props.limparPassagens()
    }

    exibirIconeLimparRetorno() {
        if (this.props.dataRetornoSelecionada) {
            return (
                <div>
                    <i className="material-icons mt-4" style={{
                        marginLeft: '-40px',
                        position: 'absolute',
                        cursor: 'pointer',
                    }} onClick={() => this.limpar()}>close</i>
                </div>)
        }
        return '';
    }

    render() {
        const {origem, destino} = this.props.venda
        if (window.location.pathname === '/') {
            return (
                <section id="topo">
                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                        <div className="carousel-indicators" style={{height: 150}}>
                            <div className='pesquisa-tela-inicial col-12' aria-labelledby='pesquisa-tela-inicial'>
                                <div className="input-busca">
                                    <div className="row">
                                        <div className="col-md-3 origem">
                                            <label id='Origem' aria-label='Origem' htmlFor="origem" className="form-label col-12">Origem</label>
                                            <SelecaoCidadeAutocomplete aria-hidden='true' icone='near_me' placeholder='Selecione a cidade'
                                                                       selecionarCidade={this.selecionarOrigem.bind(this)}
                                                                       value={origem}/>
                                        </div>
                                        <div className="col-md-3 destino">
                                            <div className="input-group">
                                                <label id='Destino' aria-label='Destino' htmlFor="destino" className="form-label col-12">Destino</label>
                                                <SelecaoCidadeAutocomplete aria-hidden='true' icone='place' placeholder='Selecione a cidade'
                                                                           selecionarCidade={this.selecionarDestino.bind(this)}
                                                                           value={destino}/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="input-group">
                                                <label id='Data-ida' aria-label='Data-ida' htmlFor="dt-ida" className="form-label col-12">Data de Ida</label>
                                                {this.exibirCalendario(true)}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="input-group">
                                                <label id='Data-volta' aria-label='Data-volta' htmlFor="dt-volta" className="form-label col-12">Data de Volta</label>
                                                {this.exibirCalendario()}
                                            </div>
                                        </div>
                                        {this.exibirIconeLimparRetorno()}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 line-btn">
                                        <button disabled={!origem || !destino || !this.props.dataIdaSelecionada}
                                            onClick={() => this.buscar()} className="btn btn-primary">Buscar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <video className="w-100 videosInicial" title="Background" autoPlay={true} loop={true}
                                       muted="muted">
                                    <source src={recuperarConfEmpresa().videoInicio} type="video/mp4"/>
                                    <track kind="captions" label="Legendas" src="legendas.vtt"/>
                                </video>
                                <div className="carousel-caption d-md-block texto-banner" style={{zIndex: 9}}>
                                    {recuperarConfEmpresa().textoBanner.map(it => it)}
                                </div>
                                <div className="carousel-caption d-md-block next-item">
                                    <a aria-label="ir para news" href="#news">
                                        <DoubleArrowOutlinedIcon className="iconDown"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        return '';
    }
}

export const mapStateToProps = state => (
    {
        dataIdaSelecionada: state.venda.dataIdaSelecionada,
        dataRetornoSelecionada: state.venda.dataRetornoSelecionada,
        venda: state.venda,
    });


export default withRouter(connect(mapStateToProps,
    {
        buscarCidadesPorNome,
        selecionarOrigem,
        selecionarDestino,
        selecionarDataRetorno,
        selecionarDataIda,
        limparPassagens,
        limparDataRetorno,
        buscarPassagens,
    })(Carousel))
import React, { Component } from 'react'

import 'react-datepicker/dist/react-datepicker.css';
import capitalizarTodasPrimeirasLetras from '../../util/capitalizarTodasPrimeirasLetras';
import { formatarReal } from '../../util/numberUtil';
import moment from 'moment'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import pt from 'date-fns/locale/pt';
import { selecionarPedido } from '../../actions/pedidosActions';
import { alterarFormaPagamento } from '../../actions/alterarFormaPagamentoActions';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { showAlertMsg } from '../../util/messagesUtil';

registerLocale('pt', pt);
setDefaultLocale('pt');

export class MeuPedido extends Component {

    selecionarPedido(pedido) {
        this.props.selecionarPedido(pedido);
        this.props.history.push('/minhas-reservas')
    }

    alterarFormaPagamento = () => {
        const { pedido } = this.props;
        const reserva = pedido.reservas[0];
        if (pedido.reservas.some(it => it.habilitadoAlterarFormaPagamento)) {
            this.props.selecionarPedido(pedido);
            if (pedido.situacaoPedido === 'CANCELADO') {
                const url = this.buscarPassagens(reserva.descCidadeOrigem, reserva.descCidadeDestino, new Date());
                this.props.history.push(url);
            } else if (pedido.situacaoPedido === 'PENDENTE') {
                this.props.history.push('/alterar-forma-pagamento')
            } else {
                this.props.selecionarPedido(null);
                showAlertMsg('Não é possível alterar a forma de pagamento.');
            }
        } else {
            const url = this.buscarPassagens(reserva.descCidadeOrigem, reserva.descCidadeDestino, new Date());
            this.props.history.push(url);
            showAlertMsg('O itinerário não está mais disponível. Selecione outro itinerário e siga com a compra.');
        }
    }

    buscarPassagens(dataIda) {
        const { pedido } = this.props;
        const reserva = pedido.reservas[0];
        let url = `/passagem-de-onibus/${reserva.descCidadeOrigem}/${reserva.descCidadeDestino}/${moment(dataIda).format('YYYY-MM-DD')}`;
        return url;
    }

    render() {
        const { pedido } = this.props;
        const dataPedido = moment(pedido.data).format('DD/MM/YYYY HH:mm');
        const imagemDestino = pedido && pedido.reservas[0].imagemDestino ? pedido.reservas[0].imagemDestino : 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/default_destinos.jpeg';
        return (
            <div className="card-group mt-2 mb-4">
                <div className='card col-sm-12 item-passagem' style={{ margin: 0 }}>
                    <div className='row'>
                        <div className="card-body card-reserva img-reserva ml-5 mb-2 col-sm-3">
                            <img className='img-reserva' src={imagemDestino} />
                        </div>
                        <div className="card-body card-reserva align-content-center col-md-8 col-sm-11 info">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Data da compra:</label>
                                    <p>{moment(dataPedido, 'DD/MM/YYYY').format('DD/MM/YYYY')}</p>
                                </div>
                                <div id='btnDetalhamento' className="col-sm-6">
                                    <button type='button' className="btn-detalhamento" onClick={() => this.selecionarPedido(pedido)}>
                                        <i className="material-icons">&#xe873;</i><a className='detalhe'>Detalhes</a></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Número do pedido:</label>
                                    <p>{pedido.id}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Origem:</label>
                                    <p>{pedido.origem}</p>
                                </div>
                                <div id='CampoDestino' className="col-sm-6">
                                    <label>Destino:</label>
                                    <p>{pedido.destino}</p>
                                </div>
                            </div>
                            <div id='valorSituacaoReserva' className="row valor-situacao-reserva">
                                <div className="col-sm-6">
                                    <label>Valor:</label>
                                    <p>{formatarReal(Number(pedido.valor).toString())}</p>
                                </div>
                                <div id='situacaoReserva' className="col-sm-6">
                                    <label>Situação:</label>
                                    <p>{capitalizarTodasPrimeirasLetras(pedido.situacaoPedido === 'CANCELADO_REMARCADO_PARCIALMENTE' ? 'cancelado parcialmente' : pedido.situacaoPedido)}</p>
                                </div>
                                {pedido.situacaoPedido === 'PENDENTE' || pedido.situacaoPedido === 'CANCELADO' ? (
                                    <div className="col-sm-6" id='btnAlterarPagamento'>
                                        <button type='button' className="btn-alterarFormaPagamento" onClick={this.alterarFormaPagamento}>
                                            <i className='fas fa-wallet' id='wallet'></i><a className='detalhe1'>Alterar forma de pagamento</a></button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(connect(null, { selecionarPedido, alterarFormaPagamento })(MeuPedido));

import {arredondamentoPadrao} from '../util/numberUtil';
import {getAmbiente} from '../util/applicationContext';

export const ADICIONAR_PASSAGEIRO = '@vendasReducer/ADICIONAR_PASSAGEIRO';
export const BUSCAR_MAPA_ITINERARIO_IDA = '@vendasReducer/BUSCAR_MAPA_ITINERARIO_IDA';
export const BUSCAR_MAPA_ITINERARIO_VOLTA = '@vendasReducer/BUSCAR_MAPA_ITINERARIO_VOLTA';
export const ALTERAR_VALOR_TOTAL_PASSAGENS = '@vendasReducer/ALTERAR_VALOR_TOTAL_PASSAGENS';
export const APLICAR_CUPOM_DESCONTO = '@vendasReducer/APLICAR_CUPOM_DESCONTO';
export const BUSCAR_PASSAGENS = '@vendasReducer/BUSCAR_PASSAGENS';
export const BUSCAR_TIPOS_DOCUMENTOS = '@vendasReducer/BUSCAR_TIPOS_DOCUMENTOS';
export const MARCAR_ASSENTO_MAPA_ITINERARIO_IDA = '@vendasReducer/MARCAR_ASSENTO_MAPA_ITINERARIO_IDA';
export const MARCAR_ASSENTO_MAPA_ITINERARIO_RETORNO = '@vendasReducer/MARCAR_ASSENTO_MAPA_ITINERARIO_RETORNO';
export const SELECIONAR_DESTINO = '@vendasReducer/SELECIONAR_DESTINO';
export const SELECIONAR_ITINERARIO_IDA = '@vendasReducer/SELECIONAR_ITINERARIO_IDA';
export const SELECIONAR_ITINERARIO_RETORNO = '@vendasReducer/SELECIONAR_ITINERARIO_RETORNO';
export const SELECIONAR_ORIGEM = '@vendasReducer/SELECIONAR_ORIGEM';
export const SELECIONAR_DATA_IDA = '@vendasReducer/SELECIONAR_DATA_IDA';
export const LIMPAR_DATA_RETORNO = '@vendasReducer/LIMPAR_DATA_RETORNO';
export const SELECIONAR_DATA_RETORNO = '@vendasReducer/SELECIONAR_DATA_RETORNO';
export const LIMPAR_PASSAGENS = '@vendasReducer/LIMPAR_PASSAGENS';
export const CRIAR_RESERVA = '@vendasReducer/CRIAR_RESERVA';
export const BUSCAR_INFORMACOES_CARTAO = '@vendasReducer/BUSCAR_INFORMACOES_CARTAO';
export const CADASTRAR_BLOQUEIO_POLTRONAS_TEMPORARIO = '@vendasReducer/CADASTRAR_BLOQUEIO_POLTRONAS_TEMPORARIO';
export const LIBERAR_BLOQUEIO_POLTRONAS_TEMPORARIO = '@vendasReducer/LIBERAR_BLOQUEIO_POLTRONAS_TEMPORARIO';
export const BUSCAR_DESTINOS_MAIS_VENDIDOS = '@vendasReducer/BUSCAR_DESTINOS_MAIS_VENDIDOS';
export const MEIO_PAGAMENTO = '@vendasReducer/MEIO_PAGAMENTO';
export const POLTRONA_DISPONIVEL_VENDA_SITE = '@vendasReducer/POLTRONA_DISPONIVEL_VENDA_SITE';
export const ALTERAR_FORMA_PAGAMANTO = '@alterarFormaPagamentoReducer/ALTERAR_FORMA_PAGAMANTO';
export const CHAVE_PUBLICA_PAG = '@vendasReducer/CHAVE_PUBLICA_PAG';

const initialState = {
    origem: null,
    destino: null,
    passagensIda: [],
    passagensRetorno: [],
    datasIdaProximas: [],
    datasRetornoProximas: [],
    itinerarioIda: null,
    itinerarioRetorno: null,
    mapaVeiculoItinerarioIda: null,
    mapaVeiculoItinerarioRetorno: null,
    poltronasSelecionadas: [],
    passageiros: [],
    tiposDocumentos: [],
    dataIdaSelecionada: null,
    dataRetornoSelecionada: null,
    valorTotalPassagens: 0.00,
    valorTotalTaxaEmbarque: 0.00,
    valorTotalGeral: 0.00,
    porcentagemTaxaServico: 0.00,
    valorTotalTaxaServico: 0.00,
    valorTotalPedagio: 0.00,
    valorCupomDesconto: 0.00,
    bloqueios: [],
    destinosMaisVendidos: [],
    pedido: null,
};
export default function reducerVendas(state = initialState, action) {
    switch (action.type) {
        case BUSCAR_PASSAGENS: {

            const {passagensIda: novaPassagensIda, passagensRetorno, datasIdaProximas, datasRetornoProximas, origem, destino, integracao} = action.payload;

            let passagemIdaFiltrada = novaPassagensIda;

            if (state.passagensIda.length > 0) {
                const dataIdaSelecionada = state.passagensIda.filter(passagem => passagem.integracao === false).map(passagem => passagem.dataPartida);
                const passagemIdaDataSelecionada = novaPassagensIda.filter(passagem => passagem.dataPartida === dataIdaSelecionada[0]);
                passagemIdaFiltrada = passagemIdaDataSelecionada.filter(newPassagem =>
                    !state.passagensIda.some(passagem => passagem.idItinerario === newPassagem.idItinerario),
                );
            }

            return {
                ...state,
                passagensIda: [...(state.passagensIda ?? []), ...(passagemIdaFiltrada ?? [])],
                passagensRetorno,
                datasIdaProximas,
                datasRetornoProximas,
                origem: origem ? origem : state.origem,
                destino: destino ? destino : state.destino,
                integracao: integracao,
            };
        }
        case CRIAR_RESERVA: {
            return {
                ...state,
                pedido: action.payload,
            }
        }
        case ALTERAR_FORMA_PAGAMANTO: {
            return {
                ...state,
                pedido: action.payload,
            }
        }
        case BUSCAR_MAPA_ITINERARIO_IDA: {
            return {
                ...state,
                mapaVeiculoItinerarioIda: action.payload,
            }
        }
        case BUSCAR_MAPA_ITINERARIO_VOLTA: {
            return {
                ...state,
                mapaVeiculoItinerarioRetorno: action.payload,
            }
        }
        case BUSCAR_DESTINOS_MAIS_VENDIDOS: {
            return {
                ...state,
                destinosMaisVendidos: action.payload,
            }
        }
        case ALTERAR_VALOR_TOTAL_PASSAGENS: {
            const valorTotalCupomDesconto = action.payload
            if (valorTotalCupomDesconto > 0) {
                const {valorTotalTaxaEmbarque, valorTotalPassagens} = state
                const novoValorTotalPassagens = valorTotalPassagens - valorTotalCupomDesconto
                const novoValorTotal = novoValorTotalPassagens + valorTotalTaxaEmbarque
                const valorTotalTaxaServico = novoValorTotal * getAmbiente().taxaServico
                const novoValorTotalGeral = novoValorTotal + valorTotalTaxaServico
                return {
                    ...state,
                    valorTotalGeral: novoValorTotalGeral,
                    valorTotalTaxaServico: valorTotalTaxaServico,
                    valorCupomDesconto: valorTotalCupomDesconto,
                }
            } else {
                const {valorTotalTaxaEmbarque, valorTotalPassagens} = state
                const valorTotal = valorTotalPassagens + valorTotalTaxaEmbarque
                const valorTotalTaxaServico = valorTotal * getAmbiente().taxaServico
                const valorTotalGeral = valorTotal + valorTotalTaxaServico
                return {
                    ...state,
                    valorTotalPassagens,
                    valorTotalTaxaEmbarque,
                    valorTotalTaxaServico,
                    valorTotalGeral,
                    valorCupomDesconto: 0.00,
                }
            }
        }
        case ADICIONAR_PASSAGEIRO: {
            const valorTotalCupomDesconto = state.valorCupomDesconto ? state.valorCupomDesconto : 0;
            let passageiros = alterarValorPassagensParaGarantiaPreco(action.payload, state);
            passageiros = passageiros.length > 0 ? passageiros : action.payload
            const valorTotalPassagens = calcularValorTotal(passageiros)
            const valorTotalTaxaEmbarque = calcularValorTotalTaxaEmbarque(passageiros)
            const valorTotal = (valorTotalPassagens + valorTotalTaxaEmbarque) - valorTotalCupomDesconto;
            const valorTotalTaxaServico = valorTotal * getAmbiente().taxaServico
            const valorTotalGeral = (valorTotal + valorTotalTaxaServico)
            return {
                ...state,
                passageiros: passageiros,
                valorTotalPassagens,
                valorTotalTaxaEmbarque,
                valorTotalTaxaServico,
                valorTotalGeral,
            }
        }
        case SELECIONAR_DESTINO: {
            return {
                ...state,
                destino: action.payload,
            };
        }
        case SELECIONAR_ORIGEM: {
            return {
                ...state,
                origem: action.payload,
            };
        }
        case SELECIONAR_ITINERARIO_IDA: {
            return {
                ...state,
                itinerarioIda: action.payload,
            }
        }
        case SELECIONAR_ITINERARIO_RETORNO: {
            return {
                ...state,
                itinerarioRetorno: action.payload,
            }
        }
        case SELECIONAR_DATA_IDA: {
            return {
                ...state,
                dataIdaSelecionada: action.payload,
            }
        }
        case SELECIONAR_DATA_RETORNO: {
            return {
                ...state,
                dataRetornoSelecionada: action.payload,
            }
        }
        case MARCAR_ASSENTO_MAPA_ITINERARIO_IDA: {
            const {assento, fileira, codigoIntegracao} = action.payload;
            const indiceFileira = state.mapaVeiculoItinerarioIda.indexOf(state.mapaVeiculoItinerarioIda.filter(f => f.id === fileira.id)[0]);
            const novo = [...state.mapaVeiculoItinerarioIda];
            const novaFileira = Object.assign({}, fileira);
            const novosAssentos = [...novaFileira.assentos];
            const indiceAssento = novaFileira.assentos.indexOf(assento);
            let novoAssento = {}
            if (codigoIntegracao) {
                novoAssento = Object.assign({}, assento, {selecionado: !assento.selecionado, id: codigoIntegracao});
            } else {
                novoAssento = Object.assign({}, assento, {selecionado: !assento.selecionado});
            }
            novosAssentos[indiceAssento] = novoAssento;
            novaFileira.assentos = novosAssentos;
            novo[indiceFileira] = novaFileira;
            return {
                ...state,
                mapaVeiculoItinerarioIda: novo,
            }
        }
        case LIMPAR_DATA_RETORNO: {
            return {
                ...state,
                dataRetornoSelecionada: null,
            }
        }
        case MARCAR_ASSENTO_MAPA_ITINERARIO_RETORNO: {
            const {assento, fileira} = action.payload;
            const indiceFileira = state.mapaVeiculoItinerarioRetorno.indexOf(state.mapaVeiculoItinerarioRetorno.filter(f => f.id === fileira.id)[0]);
            const novo = [...state.mapaVeiculoItinerarioRetorno];
            const novaFileira = Object.assign({}, fileira);
            const novosAssentos = [...novaFileira.assentos];
            const indiceAssento = novaFileira.assentos.indexOf(assento);
            const novoAssento = Object.assign({}, assento, {selecionado: !assento.selecionado});
            novosAssentos[indiceAssento] = novoAssento;
            novaFileira.assentos = novosAssentos;
            novo[indiceFileira] = novaFileira;
            return {
                ...state,
                mapaVeiculoItinerarioRetorno: novo,
            }
        }
        case BUSCAR_TIPOS_DOCUMENTOS: {
            return {
                ...state,
                tiposDocumentos: action.payload,
            }
        }
        case LIMPAR_PASSAGENS: {
            return {
                ...state,
                passagensIda: [],
                passagensRetorno: [],
            };
        }
        case
        CADASTRAR_BLOQUEIO_POLTRONAS_TEMPORARIO: {
            const bloqueios = [...state.bloqueios];
            bloqueios.push(action.payload.data);
            return {
                ...state,
                bloqueios: bloqueios,
            };
        }
        case
        LIBERAR_BLOQUEIO_POLTRONAS_TEMPORARIO: {
            const {ids, integracao} = action.payload;
            const filtroId = integracao ? 'uuid' : 'id';
        
            return {
                ...state,
                bloqueios: state.bloqueios.filter(it => !ids.includes(it[filtroId])),
            };
        }
        
        default: {
            return state
        }
    }

    // eslint-disable-next-line no-unused-vars
    function selecionarPassagem(passagens) {
        return passagens && passagens.length === 1
    }

    function calcularValorTotal(passageiros) {
        let valor = 0.00;
        if (existePassagens(passageiros)) {
            passageiros.forEach((pass) => {
                if (pass.habilitadoValorAntt) {
                    valor += Number(pass.valorANTT)
                } else {
                    valor += Number(pass.valor)
                }
            })
        }
        return Number(arredondamentoPadrao(valor))
    }

    function calcularValorTotalTaxaEmbarque(passageiros) {
        let valor = 0.00;
        if (existePassagens(passageiros)) {
            passageiros.forEach((pass) => {
                valor += Number(pass.taxaEmbarque)
            })
        }
        return Number(arredondamentoPadrao(valor))
    }

    function existePassagens(pasageiros) {
        return pasageiros && pasageiros.length > 0;
    }
}

function alterarValorPassagensParaGarantiaPreco(passageiros, state) {
    if (passageiros.length > 0) {
        if (state.bloqueios.length > 0) {
            const alteradoValorPorGarantiaPrecoPassageiros =
                passageiros.map(it => {
                    const garantiaPreco = state.bloqueios.filter(b => b.poltronaId === it.poltronaId)[0];
                    const obj = Object.assign({}, it, {valor: (Number(garantiaPreco.preco) + (it.pedagio ? Number(it.pedagio) : 0))});
                    return obj;
                });
            return alteradoValorPorGarantiaPrecoPassageiros;
        }
    }
    return [];
}

import logoAgencia from '../../../estilo/comum/img/logo-turissulpassagens.webp'
import iconAgencia from '../../../estilo/comum/img/favicon-turissul.png'
import bannerAgencia from '../../../estilo/comum/img/banner-site-agencias.png'
import bannerAgenciaMobile from '../../../estilo/comum/img/banner-site-agencias-mobile.png'
import bannerAgenciaMobile2 from '../../../estilo/comum/img/banner-site-agencias-mobile-2.png'
import ConteudoQuemSomosTuri from '../../../componentes/quem-somos/turissulpassagens/ConteudoQuemSomosTuri';
import dadosSiteComum from './dadosSiteComum';
import cardPrecoPromocao from '../../../estilo/comum/img/carouselCards/12.webp';
import cardContrateVexado from '../../../estilo/comum/img/carouselCards/17.webp';
import cardModalPromocao from '../../../estilo/comum/img/carouselCards/cupomDescontoAgencias.webp';
import cardQueroAgencia from '../../../estilo/comum/img/carouselCards/18.webp';

export const dadosTurissulpassagens = {
    ...dadosSiteComum,
    id: 'turissulpassagens',
    pastaEstilo: 'turissulpassagens',
    nome: 'Turissul Passagens',
    nomePrefixo: 'o Turissul Passagens',
    razaoSocial: 'CATARINENSE AGENCIA DE VIAGENS LTDA',
    cnpj: '08.336.161/0001-62',
    endereco:'Rua Arlei Leopoldina, bairro São João (Margem Esquerda), SAO JOAO (MARGEM ESQUERDA), SC, CEP: 88708-415',
    telefone: '(48) 3626-0327',
    wtpp: '(48) 99635-6234',
    instagram: 'https://www.instagram.com/turissul/?utm_medium=copy_link',
    tiktok: 'https://www.tiktok.com',
    facebook: 'https://www.facebook.com/turissulturismo/',
    youtube: 'youtube.com',
    email: 'turissul@turissul.com.br',
    emailSuporte: 'turissul@turissul.com.br',
    lkemailSuporte: 'mailto:turissul@turissul.com.br',
    lktelefone: 'tel:554836260327',
    twitter: '',    
    lkemail: 'mailto:turissul@turissul.com.br',
    lkwhats: 'https://api.whatsapp.com/send?phone=554836266234&text=Olá. Preciso de atendimento.',
    logo: logoAgencia,
    logoCabecacho: null,
    logoRodape: null,
    icone: iconAgencia,
    quemSomos: ConteudoQuemSomosTuri,
    videoInicio: bannerAgencia,
    imgMobile: bannerAgenciaMobile,
    imgMobile2: bannerAgenciaMobile2,
    idsTagManager: ['GTM-PHGZ336'],
    bannerModalPromocao: 'https://imagens-banner-promocao-sites.s3.amazonaws.com/cupom+de+desconto+expresso+diamante+-+transbrasil++-+passagens+adamantina.webp',
    cardPrecoPromocao: cardPrecoPromocao,
    cardContrateVexado: cardContrateVexado,
    cardModalPromocao: cardModalPromocao,
    cardQueroAgencia: cardQueroAgencia,
    banner: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/5.webp',
    bannerMobile: 'https://imagens-vexado-live.s3.sa-east-1.amazonaws.com/5.mobile.webp',
    distribusion: false,
    exibePopUpPromocao: false
};
import moment from 'moment';
import {URL} from '../util/ambienteActions';
import axios from 'axios';
import {SLOW_REQUEST_CONFIG, SLOW_REQUEST_CONFIG_INT_DIST} from '../util/loadingUtil';
import {getVendaPendente, saveVendaPendenteLocalStorage} from '../seguranca/securityContext';
import {
    ADICIONAR_PASSAGEIRO,
    ALTERAR_VALOR_TOTAL_PASSAGENS,
    APLICAR_CUPOM_DESCONTO,
    BUSCAR_DESTINOS_MAIS_VENDIDOS,
    BUSCAR_INFORMACOES_CARTAO,
    BUSCAR_MAPA_ITINERARIO_IDA,
    BUSCAR_MAPA_ITINERARIO_VOLTA,
    BUSCAR_PASSAGENS,
    CADASTRAR_BLOQUEIO_POLTRONAS_TEMPORARIO,
    CRIAR_RESERVA,
    LIBERAR_BLOQUEIO_POLTRONAS_TEMPORARIO,
    LIMPAR_DATA_RETORNO,
    LIMPAR_PASSAGENS,
    MARCAR_ASSENTO_MAPA_ITINERARIO_IDA,
    MARCAR_ASSENTO_MAPA_ITINERARIO_RETORNO, MEIO_PAGAMENTO, CHAVE_PUBLICA_PAG,
    SELECIONAR_DATA_IDA,
    SELECIONAR_DATA_RETORNO,
    SELECIONAR_DESTINO,
    SELECIONAR_ITINERARIO_IDA,
    SELECIONAR_ITINERARIO_RETORNO,
    SELECIONAR_ORIGEM,
} from '../reducers/vendasReducer';

import {salvarDestinosMaisVendidos} from '../util/applicationContext';

export function selecionarOrigem(origem) {
    return {
        type: SELECIONAR_ORIGEM,
        payload: origem,
    }
}

export function selecionarDestino(destino) {
    return {
        type: SELECIONAR_DESTINO,
        payload: destino,
    }
}

export function selecionarDataIda(data) {
    return {
        type: SELECIONAR_DATA_IDA,
        payload: data,
    }
}

export function limparPassagens() {
    return {
        type: LIMPAR_PASSAGENS,
    }
}

export function limparDataRetorno() {
    return {
        type: LIMPAR_DATA_RETORNO,
    }
}

export function selecionarDataRetorno(data) {
    return {
        type: SELECIONAR_DATA_RETORNO,
        payload: data,
    }
}

export function adicionarPassageiros(passagens) {
    return {
        type: ADICIONAR_PASSAGEIRO,
        payload: passagens,
    }
}

export function alterarValorTotalPassagens(novoValorTotal) {
    return {
        type: ALTERAR_VALOR_TOTAL_PASSAGENS,
        payload: novoValorTotal,
    }
}


export function selecionarItinerarioRetorno(itinerario) {
    return {
        type: SELECIONAR_ITINERARIO_RETORNO,
        payload: itinerario,
    }
}

export function selecionarItinerarioIda(itinerario) {
    return {
        type: SELECIONAR_ITINERARIO_IDA,
        payload: itinerario,
    }
}

export function selecionarAssentoMapaItinerarioIda(assento, fileira, codigoIntegracao) {
    return {
        type: MARCAR_ASSENTO_MAPA_ITINERARIO_IDA,
        payload:
            {
                assento,
                fileira,
                codigoIntegracao
            },
    }
}

export function selecionarAssentoMapaItinerarioRetorno(assento, fileira, codigoIntegracao) {
    return {
        type: MARCAR_ASSENTO_MAPA_ITINERARIO_RETORNO,
        payload:
            {
                assento,
                fileira,
                codigoIntegracao
            },
    }
}

export function buscarPassagens(idCidadeOrigem, idCidadeDestino, dataIda, dataRetorno, idEmpresa) {
    const url = montarUrlBuscarPassagens(dataIda, dataRetorno, idCidadeOrigem, idCidadeDestino, idEmpresa, false);
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG_INT_DIST).then(
        response => dispatch({
            type: BUSCAR_PASSAGENS,
            payload: {...response.data, dataIda},
        }),
    )
}

export function buscarPassagensDistribusion(idCidadeOrigem, idCidadeDestino, dataIda, dataRetorno, idEmpresa) {
    const url = montarUrlBuscarPassagens(dataIda, dataRetorno, idCidadeOrigem, idCidadeDestino, idEmpresa, true);
    return dispatch => {
        axios.get(url, SLOW_REQUEST_CONFIG_INT_DIST)
            .then(response => {
                dispatch({
                    type: BUSCAR_PASSAGENS,
                    payload: {...response.data, dataIda},
                });
            })
            .catch(error => {
                console.error('Ocorreu um erro na requisição:', error);
            });
    };
}

export function buscarPassagensPorNomeCidades(idCidadeOrigem, idCidadeDestino, dataIda, dataRetorno, idEmpresa) {
    const url = montarUrlBuscarPassagensPorNomeCidades(dataIda, dataRetorno, idCidadeOrigem, idCidadeDestino, idEmpresa, false);
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG_INT_DIST).then(
        response => dispatch({
            type: BUSCAR_PASSAGENS,
            payload: {...response.data, dataIda},
        }),
    )
}

export function buscarPassagensPorNomeCidadesDistribusion(idCidadeOrigem, idCidadeDestino, dataIda, dataRetorno, idEmpresa) {
    const url = montarUrlBuscarPassagensPorNomeCidades(dataIda, dataRetorno, idCidadeOrigem, idCidadeDestino, idEmpresa, true);
    return dispatch => {
        axios.get(url, SLOW_REQUEST_CONFIG_INT_DIST)
            .then(response => {
                dispatch({
                    type: BUSCAR_PASSAGENS,
                    payload: {...response.data, dataIda},
                });
            })
            .catch(error => {
                console.error('Ocorreu um erro na requisição:', error);
            });
    };
}

export function aplicarCupomDesconto(valorTotal, identificadorCupom) {
    const url = `${URL}/reserva/public/aplicar-cupom-desconto?valorTotalPedido=${valorTotal}&idetificadorCupom=${identificadorCupom}`;
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: APLICAR_CUPOM_DESCONTO,
            payload: response.data,
        }),
    )
}

export function buscarMapaVeiculoItinerario(codItinerario, codOrigem, codDestino, seIda, categoria) {
    const url = `${URL}/mapas-veiculos/public/itinerario/${codItinerario}/origem/${codOrigem}/destino/${codDestino}?categoria=${categoria}`;
    if (seIda) {
        return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
            response => dispatch({
                type: BUSCAR_MAPA_ITINERARIO_IDA,
                payload: response.data,
            }),
        )
    }

    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: BUSCAR_MAPA_ITINERARIO_VOLTA,
            payload: response.data,
        }),
    )
}

export function buscarMapaVeiculoItegracao(comandoMapaVeiculo) {
    const {
        departureStation,
        arrivalStation,
        departureTime,
        arrivalTime,
        marketingCarrier,
        fareClass,
    } = comandoMapaVeiculo;

    const url = `${URL}/mapas-veiculos/public/recuperar?departureStation=${departureStation}&arrivalStation=${arrivalStation}&departureTime=${departureTime}&arrivalTime=${arrivalTime}&marketingCarrier=${marketingCarrier}&fareClass=${fareClass}`;
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: BUSCAR_MAPA_ITINERARIO_IDA,
            payload: response.data,
        }),
    )

}

export function validarVendaPendente() {
    const venda = getVendaPendente();
    if(venda && !venda.api === 'VEXADO'){
        // eslint-disable-next-line no-undef
        if (venda && _.size(venda.reservas) > 0) {
            venda.reservas.forEach(reserva => {
                const url = `${URL}/poltronas/public/itinerario/${reserva.itinerarioId}/origem/${reserva.idCidadeOrigem}/destino/${reserva.idCidadeDestino}/poltrona/${reserva.poltronaId}/garantiaPrecoUuid/${reserva.garantiaPrecoUuid}/disponibilidade`
                axios.get(url, SLOW_REQUEST_CONFIG).then(resp => {
                    if (resp.status === 200 && !resp.data) {
                        saveVendaPendenteLocalStorage(null)
                    }
                })
            })
        }
    }
}

export function criarBloqueioPoltronasTemporario(dados, ehIntegracao) {
const address = ehIntegracao ? `${URL}/bloqueio-poltrona-temporario/integracao` : `${URL}/bloqueio-poltrona-temporario`;
    return dispatch => axios.post(address, dados, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: CADASTRAR_BLOQUEIO_POLTRONAS_TEMPORARIO,
            payload: response,
        }),
    );
}

export function liberarBloqueioPoltronasTemporario(ids, integracao) {
    const address = integracao ? `${URL}/bloqueio-poltrona-temporario/integracao/liberar` : `${URL}/bloqueio-poltrona-temporario/liberar`;
    return dispatch => axios.post(address, ids, SLOW_REQUEST_CONFIG).then(
        dispatch({
            type: LIBERAR_BLOQUEIO_POLTRONAS_TEMPORARIO,
            payload: {ids, integracao},
        }),
    );
}

export function criarReserva(comandoReserva) {
    const url = `${URL}/reserva/cadastrar/site`;
    return dispatch => axios.post(url, comandoReserva, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: CRIAR_RESERVA,
            payload: response.data,
        }),
    )
}


function montarUrlBuscarPassagens(dataIda, dataRetorno, idCidadeOrigem, idCidadeDestino, idEmpresa, integracao) {
    moment(dataIda).format('YYYY-MM-DD');
    const address = integracao ? URL+'/passagem/public/integracao/' : URL+'/passagem/public/'
    let url = `${address}origem/${idCidadeOrigem}/destino/${idCidadeDestino}?dataIda=${moment(dataIda).format('YYYY-MM-DD')}`;
    if (dataRetorno) {
        url += `&dataRetorno=${moment(dataRetorno).format('YYYY-MM-DD')}`;
    }
    if (idEmpresa) {
        url += `&empresa=${idEmpresa}`
    }
    return url
}

function montarUrlBuscarPassagensPorNomeCidades(dataIda, dataRetorno, idCidadeOrigem, idCidadeDestino, idEmpresa, integracao) {
    moment(dataIda).format('YYYY-MM-DD');
    const address = integracao ? URL+'/passagem/public/integracao/' : URL+'/passagem/public/'
    let url = `${address}origem/nome/${idCidadeOrigem}/destino/nome/${idCidadeDestino}?dataIda=${moment(dataIda).format('YYYY-MM-DD')}`;
    if (dataRetorno) {
        url += `&dataRetorno=${moment(dataRetorno).format('YYYY-MM-DD')}`;
    }
    if (idEmpresa) {
        url += `&empresa=${idEmpresa}`
    }
    return url
}

export const buscarInformacoesCartao = url => dispatch => axios.get(url, SLOW_REQUEST_CONFIG)
    .then((response) => {
        return dispatch({
            type: BUSCAR_INFORMACOES_CARTAO,
            payload: response,
        })
    })
    .catch(err => dispatch({
        type: BUSCAR_INFORMACOES_CARTAO,
        payload: {err},
    }));

export function buscarDestinosMaisVendidos() {
    const url = `${URL}/plataformas/public/destinos-mais-vendidos`;
    return dispatch => axios.get(url)
        .then(response => {
                const data = response.data;
                salvarDestinosMaisVendidos(data);
                return dispatch({
                    type: BUSCAR_DESTINOS_MAIS_VENDIDOS,
                    payload: data,
                })
            },
        );
}

export function recuperarOpcoesPagamento(valorTotal, bandeira, binCartaoCredito) {
    const url = `${URL}/reserva/public/opcoes-pagamento?valorTotalPedido=${valorTotal}&bandeira=${bandeira}&binCartaoCredito=${binCartaoCredito}`;
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: APLICAR_CUPOM_DESCONTO,
            payload: response.data,
        }),
    )
}

export function recuperarMeioPagamento() {
    const url = `${URL}/plataformas/public/meio-pagamento`;
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: MEIO_PAGAMENTO,
            payload: response.data,
        }),
    )
}

export function recuperarChavePublica() {
    const url = `${URL}/reserva/chave-publica`
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: CHAVE_PUBLICA_PAG,
            payload: response.data,
        }),
    )
}

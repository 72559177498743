import React, { Component } from 'react'
import InstagramIcon from '@material-ui/icons/Instagram'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import FacebookIcon from '@material-ui/icons/Facebook'
import PersonOutlineOutlined from '@material-ui/icons/PersonOutlineOutlined'
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined'
import { recuperarConfEmpresa } from '../../util/applicationContext';
import { Link, withRouter } from 'react-router-dom';
import capitalizarTodasPrimeirasLetras from '../../util/capitalizarTodasPrimeirasLetras';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import $ from 'jquery'

export class BarraNavegacao extends Component {

    componentDidMount() {
        this.fecharMenuLateral();
    }

    exibirPerfilUsuarioMenuLateral() {
        return (<div className='navbar menu'>
            <div className="nav-item">
                <HashLink id='meuPerfil' className="nav-link" to="/perfil" onClick={() => this.fecharMenuLateral()}>
                    <PersonOutlineOutlined className='person-perfil' /> Meu Perfil
                </HashLink>
            </div>
            <div className="nav-item">
                <HashLink id='meusPedidos' className="nav-link" to="/meus-pedidos" onClick={() => this.fecharMenuLateral()}>
                    <i className="material-icons meus-pedidos">&#xe873;</i>Meus Pedidos
                </HashLink>
            </div>
        </div>)
    }

    recuperarExibicaoNav() {
        if (window.location.pathname === '/'
            && recuperarConfEmpresa().id !== 'amarelinho') {
            return ''
        }
        if (window.location.pathname === '/atendimento'
            || recuperarConfEmpresa().id === 'amarelinho') {
            return 'interno'
        }
        return 'interno'
    }

    fecharMenuLateral() {
        $('#navbarSupportedContent .navbar-nav #close').click(function () {
            $('#navbarSupportedContent').removeClass('show');
        })
        $('#navbarSupportedContent .navbar-nav .nav-link').click(function () {
            $('#navbarSupportedContent').removeClass('show');
        })
        $('#navbarSupportedContent .navbar-nav .btn-login').click(function () {
            $('#navbarSupportedContent').removeClass('show');
        })
    }

    exibirMenuLateral() {
        const empresa = recuperarConfEmpresa();
        const isVexado = empresa.id === 'vexado';

        return (<div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav ml-auto">
                <div className='navbar menu'>
                    <a aria-label="ir para menu" href="#">Menu</a>
                    <div id="close">
                        <i className="material-icons close">&#xe5cd;</i>
                    </div>
                </div>
                {this.props.dadosAutenticacao.isAuthenticated ?
                    <div className='nav-item'>
                        <p className='nome-usuario'>
                            Olá, {this.props.nomeUsuario}
                        </p>
                    </div> : ''}
                {this.props.dadosAutenticacao.isAuthenticated ? this.exibirPerfilUsuarioMenuLateral() : ''}
                <div className="nav-item i">
                    <HashLink id='cardDestinos1' className="nav-link" to="/cards-destinos/#secao-cards-destinos">
                        Destinos + Procurados
                    </HashLink>
                </div>
                <div className="nav-item i">
                    <HashLink id='cardDestinos2' className="nav-link trecho" to="/principais-trechos/#secao-trechos">
                        Principais Trechos
                    </HashLink>
                </div>
                <div className="nav-item i">
                    <HashLink className="nav-link" to="/quem-somos/#quem-somos">
                        Quem somos
                    </HashLink>
                </div>
                <div>
                    {recuperarConfEmpresa().id === 'amarelinho' ? <div className="nav-item i">
                        <HashLink className="nav-link" to="/nossa-frota#nossa-frota">
                            Nossa Frota
                        </HashLink>
                    </div> : ''}
                </div>
                {isVexado ? <div className="nav-item i vexado">
                    <a className="nav-link" href="/quero-uma-agencia#secao-quero-uma-agencia">Quero uma Agência</a>
                </div> : ''}
                {isVexado ? <div className="nav-item i vexado">
                    <a className="nav-link" href="/sistema-vexado#secao-sistema-vexado">Sistema Vexado</a>
                </div> : ''}
                {isVexado ? <div className="nav-item i vexado">
                    <a className="nav-link" href="/vexado-encomendas#secao-vexado-express">Encomendas Express</a>
                </div> : ''}
                {isVexado ? <div className="nav-item i vexado">
                    <a className="nav-link" href="/bilhete-na-mao">Bilhete na mão</a>
                </div> : ''}
                {recuperarConfEmpresa().id === 'brasilpassagens' ||
                    recuperarConfEmpresa().id === 'amarelinho' ||
                    recuperarConfEmpresa().id === 'expressoadamantina' ? <div className="nav-item i">
                    <a className="nav-link" href="/atendimento/">
                        Atendimento
                    </a>
                </div> : ''}
               <div className="nav-item i blog">
                    {recuperarConfEmpresa().id === 'brasilpassagens' ? 
                        <a className="nav-link" href='https://www.brasilpassagensblog.com/' target="_blank" rel="noreferrer">
                            Blog</a> :
                        <a className="nav-link" href='https://www.vexadoblog.com/' target="_blank" rel="noreferrer">
                            Blog</a>}
                </div>
                {this.props.dadosAutenticacao.isAuthenticated ?
                    <div className='logi'>
                        <button className="btn btn-danger btn-exit"
                            onClick={() => {
                                this.fecharMenuLateral()
                                this.props.history.push('/logout')
                            }
                            }>Sair
                        </button>
                    </div> :
                    <div className='logi'>
                        <button className="btn-login"
                            onClick={() => this.props.history.push('/login')
                            }>Fazer Login
                        </button>
                    </div>}
                <section className="secao-redes-sociais">
                    <div className="nav-item redes-sociais">
                        <a target="_blank" rel="noreferrer" className="nav-link redes" href={recuperarConfEmpresa().instagram} aria-label="instagram">
                            <InstagramIcon />
                        </a>
                    </div>
                    <div className="nav-item redes-sociais">
                        <a target="_blank" rel="noreferrer" className="nav-link redes" href={recuperarConfEmpresa().facebook} aria-label="facebook">
                            <FacebookIcon />
                        </a>
                    </div>
                </section>
            </div>
        </div>)
    }

    exibirPerfilUsuario() {
        return <li className="nav-item redes-sociais login">
            <a className="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <AccountCircleOutlinedIcon />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
                <div className="dropdown-header text-center">
                    <strong>Perfil</strong>
                </div>
                <div className="dropdown-content text-center">
                    <a className="dropdown-item drop-item-texto">
                        {this.props.nomeUsuario}
                    </a>
                </div>
                <div className="dropdown-content text-center">
                    <Link className="dropdown-item custom-itens-dd" to='/perfil'>
                        <span>Meu perfil</span>
                    </Link>
                </div>
                <div className="dropdown-content text-center">
                    <Link id="item-sair" className="dropdown-item custom-itens-dd" to='/meus-pedidos'>
                        <span>Meus pedidos</span>
                    </Link>
                </div>
                <div className="dropdown-content text-center">
                    <a id="item-sair" className="dropdown-item custom-itens-dd" target="_blank" rel="noreferrer" href='https://docs.google.com/forms/d/e/1FAIpQLSe5Vz_3VcHnFRAPokthDw7Kbb259mSxqFbfsyHNlMxr_FNchw/viewform'>
                        <span>Solicitar Gratuidade</span>
                    </a>
                </div>
                <div className="dropdown-content text-center">
                    <Link id="item-sair" className="dropdown-item custom-itens-dd" to='/logout'>
                        <i className="material-icons">
                            &#xe8ac;
                        </i> <span className="lbl-sair">Sair</span>
                    </Link>
                </div>
            </div>
        </li>
    }

    render() {
        const empresa = recuperarConfEmpresa();
        const isVexado = empresa.id === 'vexado';

        return (
            <div className={this.recuperarExibicaoNav()}>
                {this.exibirMenuLateral()}
                <nav id='containerLogo' className="navbar navbar-expand-lg navbar-light">
                    <div className='container'>
                        <div className="navbar-brand font-weight-normal">
                            {recuperarConfEmpresa().pastaEstilo === 'agencia' ||
                            recuperarConfEmpresa().pastaEstilo === 'expressoadamantinanovo' ||
                            recuperarConfEmpresa().pastaEstilo === 'pegabus' ||
                            recuperarConfEmpresa().pastaEstilo === 'turissulpassagens' ?
                                <a className="nav-link brand" href='/'>
                                    {recuperarConfEmpresa().logoCabecacho}
                                    <div className='row'>
                                        <div className='col-md-12'><p
                                            className='denominacao'>{recuperarConfEmpresa().tipo}</p></div>
                                        <div className='col-md-12'><p
                                            className='nome-agencia'>{recuperarConfEmpresa().nome}</p></div>
                                    </div>
                                </a>
                                :
                                <a className="nav-link" href='/' id="logo-topo">
                                    {recuperarConfEmpresa().logoCabecacho}
                                </a>
                            }
                        </div>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <MenuOutlinedIcon/>
                        </button>

                        {recuperarConfEmpresa().id === 'brasilpassagens' ||
                        recuperarConfEmpresa().id === 'amarelinho' ||
                        recuperarConfEmpresa().id === 'expressoadamantina' ? <div className="navbar-nav nav-mobile">
                            <div className="nav-item redes-sociais">
                                <a target="_blank" rel="noreferrer" className="nav-link"
                                   href={recuperarConfEmpresa().instagram} aria-labelledby="instagram">
                                    <InstagramIcon/>
                                </a>
                            </div>
                            <div className="nav-item redes-sociais">
                                <a target="_blank" rel="noreferrer" className="nav-link"
                                   href={recuperarConfEmpresa().facebook} aria-labelledby="facebook">
                                    <FacebookIcon/>
                                </a>
                            </div>
                            {this.props.dadosAutenticacao.isAuthenticated ? this.exibirPerfilUsuario() :
                                <div className="nav-item redes-sociais login">
                                    <Link className="nav-link" to='/login' aria-labelledby="login">
                                        <PersonOutlineOutlined/>
                                    </Link>
                                </div>
                            }
                        </div> : ''}

                        <div className={`collapse navbar-collapse ${isVexado ? 'vexado-navbar' : ''}`}
                             id="navbarSupportedContent">

                            <div className="navbar-nav ml-auto">
                                <div className="nav-item">
                                    <HashLink id='cardDestinos3' className="nav-link"
                                              to="/cards-destinos/#secao-cards-destinos">
                                        Destinos + Procurados
                                    </HashLink>
                                </div>
                                <div className="nav-item">
                                    <HashLink id='cardDestinos4' className="nav-link trecho"
                                              to="/principais-trechos/#secao-trechos">
                                        Principais Trechos
                                    </HashLink>
                                </div>
                                <div className="nav-item">
                                    <HashLink className="nav-link" to="/quem-somos/#quem-somos">
                                        Quem somos
                                    </HashLink>
                                </div>
                                <div>
                                    {recuperarConfEmpresa().id === 'amarelinho' ? <div className="nav-item">
                                        <HashLink className="nav-link" to="/nossa-frota#nossa-frota">
                                            Nossa Frota
                                        </HashLink>
                                    </div> : ''}
                                </div>
                                {isVexado ? <div className="nav-item vexado">
                                    <a className="nav-link" href="/quero-uma-agencia#secao-quero-uma-agencia">Quero uma Agência</a>
                                </div> : ''}
                                {isVexado ? <div className="nav-item vexado">
                                    <a className="nav-link" href="/sistema-vexado#secao-sistema-vexado">Sistema Vexado</a>
                                </div> : ''}
                                {isVexado ? <div className="nav-item vexado">
                                    <a className="nav-link" href="/vexado-encomendas#secao-vexado-express">Encomendas Express</a>
                                </div> : ''}
                                {isVexado ? <div className="nav-item vexado">
                                    <a className="nav-link" href="/bilhete-na-mao">Bilhete na mão</a>
                                </div> : ''}
                                {recuperarConfEmpresa().id === 'brasilpassagens' ||
                                recuperarConfEmpresa().id === 'amarelinho' ||
                                recuperarConfEmpresa().id === 'expressoadamantina' ||
                                recuperarConfEmpresa().id === 'transbrasilpassagens' ? <div className="nav-item">
                                    <a className="nav-link" href="/atendimento/#secao-atendimento">
                                        Atendimento
                                    </a>
                                </div> : ''}
                                <section className="secao-redes-sociais">
                                    <div className="nav-item blog">
                                        {recuperarConfEmpresa().id === 'brasilpassagens' ?
                                            <a className="nav-link" href='https://www.brasilpassagensblog.com/'
                                               target="_blank" rel="noreferrer">
                                                Blog</a> :
                                            <a className="nav-link" href='https://www.vexadoblog.com/' target="_blank"
                                               rel="noreferrer">
                                                Blog</a>}
                                    </div>
                                    <div className="nav-item redes-sociais">
                                        <a target="_blank" rel="noreferrer" className="nav-link"
                                           href={recuperarConfEmpresa().instagram} aria-label="instagram">
                                            <InstagramIcon/>
                                        </a>
                                    </div>
                                    <div className="nav-item redes-sociais">
                                        <a target="_blank" rel="noreferrer" className="nav-link"
                                           href={recuperarConfEmpresa().facebook} aria-label="facebook">
                                            <FacebookIcon/>
                                        </a>
                                    </div>
                                </section>
                                {this.props.dadosAutenticacao.isAuthenticated ? this.exibirPerfilUsuario() :
                                    <div className="nav-item redes-sociais login">
                                        <Link className="nav-link" to='/login' aria-label="login">
                                            <PersonOutlineOutlined/>
                                        </Link>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

export const mapStateToProps = state => (
    {
        nomeUsuario: capitalizarTodasPrimeirasLetras(state.usuario.dadosAutenticacao.nomeUsuario),
        dadosAutenticacao: state.usuario.dadosAutenticacao,
    }
)


export default withRouter(connect(mapStateToProps, {})(BarraNavegacao))

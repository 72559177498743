import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPagSeguro } from '../actions/setPagSeguro';

class PagSeguroLoader extends Component {
  componentDidMount() {
    if (document.getElementById('pagseguro-sdk')) {
      this.props.setPagSeguro(window.PagSeguro);
      return;
    }

    const script = document.createElement('script');
    script.id = 'pagseguro-sdk';
    script.src = 'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js';
    script.async = true;

    script.onload = () => {
      this.props.setPagSeguro(window.PagSeguro);
    };

    document.body.appendChild(script);
  }

  componentWillUnmount() {
    const script = document.getElementById('pagseguro-sdk');
    if (script) {
      document.body.removeChild(script);
    }
  }

  render() {
    return <></>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPagSeguro: (pagSeguro) => dispatch(setPagSeguro(pagSeguro)),
});

// Conectar o componente ao Redux
export default connect(null, mapDispatchToProps)(PagSeguroLoader);

import React, { Component } from 'react'
import CadastroNews from '../layout/CadastroNews';
import CardsDestino from '../layout/DestinosMaisVendidos/CardsDestinos';
import CardsGuiasDicas from '../layout/CardsGuiaDicas';
import Carousel from './Carousel';
import CarouselAgencia from './CarouselAgencia';
import { recuperarConfEmpresa } from '../../util/applicationContext';
import { limparPassagens} from '../../actions/vendasActions';
import BotaoVoltarAoTopo from '../layout/BotaoVoltarAoTopo';
import CarouselCards from '../layout/CarouselCards';
import CardQueroUmaAgencia from '../layout/CardQueroUmaAgencia';
import CardsTopTrechos from '../layout/CardsTopTrechos';
import CardsEmpresas from '../layout/CardsEmpresas';
import CardsTopRedePesquisa from '../layout/CardsTopRedePesquisa';
import {connect} from 'react-redux';

export class PaginaInicial extends Component {
    componentDidMount() {
        this.props.limparPassagens();
    }

  retornaCardsTopTrechos() {
    if (recuperarConfEmpresa().pastaEstilo === 'politurtransfer') {
      return <CardsEmpresas />
    }

    return <CardsTopTrechos />
  }

  returnaCardsEmpresas() {
    if (recuperarConfEmpresa().pastaEstilo === 'politurtransfer') {
      return <CardsTopTrechos />
    }

    return (
      recuperarConfEmpresa().pastaEstilo === 'amarelinho' ||
      recuperarConfEmpresa().pastaEstilo === 'basilioebasilio' ||         
      recuperarConfEmpresa().pastaEstilo === 'transbrasilpassagens' ||
      recuperarConfEmpresa().pastaEstilo === 'turissulpassagens' ||
      recuperarConfEmpresa().pastaEstilo === 'vipbrasil'
        ? '' : <CardsEmpresas />
    )
  }

  render() {
    return (
      <div>
        {recuperarConfEmpresa().pastaEstilo === 'agencia' ||          
          recuperarConfEmpresa().pastaEstilo === 'turissulpassagens' ||
          recuperarConfEmpresa().pastaEstilo === 'pegabus' ||
          recuperarConfEmpresa().pastaEstilo === 'basilioebasilio' ||
          recuperarConfEmpresa().pastaEstilo === 'transbrasilpassagens' ?
          <CarouselAgencia /> : <Carousel />}
        {recuperarConfEmpresa().pastaEstilo !== 'politurtransfer' && <CadastroNews />}
        {this.returnaCardsEmpresas()}
        <CardsDestino />
        {recuperarConfEmpresa().pastaEstilo === 'agencia' ||          
          recuperarConfEmpresa().pastaEstilo === 'vexado' ||
          recuperarConfEmpresa().pastaEstilo === 'expressoadamantinanovo' ||
          recuperarConfEmpresa().pastaEstilo === 'brasilpassagens' ?
        <CardsTopRedePesquisa /> : ''}
        {this.retornaCardsTopTrechos()}
        <CarouselCards />
        <CardsGuiasDicas site={recuperarConfEmpresa().pastaEstilo} />
        <CardQueroUmaAgencia />
        <BotaoVoltarAoTopo />
      </div>
    )
  }
}

export default connect(null, {limparPassagens})(PaginaInicial)

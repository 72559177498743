export const BUSCAR_MINHAS_RESERVAS = '@reservasReducer/BUSCAR_MINHAS_RESERVAS';
export const SELECIONAR_RESERVA = '@reservasReducer/SELECIONAR_RESERVA';
export const CANCELAR_RESERVA = '@reservasReducer/CANCELAR_RESERVA';
export const BUSCAR_RESERVA = '@reservasReducer/BUSCAR_RESERVA';

const initialState = {
    minhasReservas: [],
    reservaSelecionada: null,
    reservaSelecionadaBilheteNaMao: null
};


export default function reducerReservas(state = initialState, action) {
    switch (action.type) {
        case BUSCAR_MINHAS_RESERVAS: {
            return {
                ...state,
                minhasReservas: action.payload.data,
            }
        }

        case SELECIONAR_RESERVA: {            
            return {
                ...state,
                reservaSelecionada: action.payload,
            }
        }

        case BUSCAR_RESERVA: {
            return {
                ...state,
                reservaSelecionadaBilheteNaMao: action.payload.data
            }
        }

        default: {
            return state
        }
    }

}
import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import loading from './loadingReducer'
import loadingInteg from './loadingReducer'
import venda from './vendasReducer'
import reserva from './reservasReducer'
import pedido from './pedidosReducer'
import usuario from './usuariosReducer'
import messages from './messagesReducer';
import promocao from './promocaoReducer';
import empresa from './empresasReducer'
import openGraph from './reducerOpenGraph'
import pagSeguro from './pagSeguroReducer'

const rootReducer = combineReducers({
    form: formReducer,

    loading,
    loadingInteg,
    usuario,
    messages,
    venda,
    reserva,
    promocao,
    pedido,
    empresa,
    openGraph,
    pagSeguro
});

export default rootReducer

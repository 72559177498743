
export const SET_PAGSEGURO = '@pagseguroReducer/SET_PAGSEGURO'

const initialState = {
    pagSeguro: null,
};
  
const pagSeguroReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGSEGURO:
        return {
            ...state,
            pagSeguro: action.payload,
        };
        default:
        return state;
    }
};

export default pagSeguroReducer

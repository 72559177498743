import React from 'react'
import { connect } from 'react-redux';
import MinhaReserva from './MinhaReserva';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import { MapaEmbarque } from '../passagem/MapaEmbarque';
import { MapaDesembarque } from '../passagem/MapaDesembarque';
registerLocale('pt', pt);
setDefaultLocale('pt');

export class PaginaMinhasReservas extends React.Component {
    constructor(props) {
        super(props);        
        this.state = { abaSelecionada: 'proximas' };
    }

    exibirTabelaReservas() {
        if (this.props.pedido && this.props.pedido.reservas) {
            const viagens = this.props.pedido.reservas.map((reserva) => <MinhaReserva  pedido = {this.props.pedido} reserva={reserva} key={reserva.id} />);
            if (viagens.length) {
                return viagens
            }
        }
        return this.props.history.push('/');
    }

    abaSelecionada(aba) {
        if (aba === this.state.abaSelecionada) {
            return 'nav-link active';
        }
        return 'nav-link';
    }

    exibirMapaEmbarqueDesembarque() {
        const reserva = this.props.pedido.reservas[0];
        return (
            <div className="card-body active">
                <div id='maisInfoContainer' className='container'>
                    <div className='avisoInformativo'>
                        <div className='conteudoWarning'>
                            <i id='iconWarning' className="fa fa-warning"></i>
                            Você precisa retirar a passagem neste local: {reserva.embarque}
                        </div>
                    </div>
                    <div id='containerActive' className='row'>
                        <div className='sectionMaps'>
                            <div className='sectionMapa1'>
                                <div className='embarque'>
                                    <p><b>Embarque:</b>{reserva.descCidadeOrigem}</p>
                                </div>
                                <div className='enderecoEmbarque'>
                                    <p><b>Endereço do embarque:</b>{reserva.embarque}</p>
                                </div>
                                {reserva.longitudeOrigem && reserva.latitudeOrigem ?
                                    <div className='renderMapa'>
                                        <MapaEmbarque
                                            passagem={reserva}
                                        />
                                    </div> : ''}
                            </div>
                            <div className='sectionMapa2'>
                                <div className='desembarque'>
                                    <p><b>Desembarque:</b>{reserva.descCidadeDestino}</p>
                                </div>
                                <div className='enderecoDesembarque'>
                                    <p><b>Endereço do desembarque:</b>{reserva.desembarque}</p>
                                </div>
                                {reserva.longitudeDestino && reserva.latitudeDestino ?
                                    <div className='renderMapa'>
                                        <MapaDesembarque
                                            passagem={reserva}
                                        />
                                    </div> : ''}
                            </div>
                        </div>
                        <div id='containerInform' className='col-md-6 col-sm-12'>
                            <div className='sectionInformativos'>
                                <div id='informativo1' className='row informativos'>
                                    <span id='informativoIcons' className="fa fa-map-marker"></span>
                                    <h3 id='tituloInformativos'><b>Paradas para embarque e desembarque</b></h3>
                                    <div className='informativoText'>
                                        As paradas são de aproximadamente 20 minutos.
                                    </div>
                                </div>
                                <div id='informativo2' className='row informativos'>
                                    <span id='informativoIcons' className="fas fa-retweet"></span>
                                    <h3 id='tituloInformativos'><b>Troca, cancelamento e reembolso</b></h3>
                                    <div className='informativoText'>
                                        Nenhuma taxa é cobrada para fazer trocas/alterações e quaisquer valores pagos
                                        por clientes são devolvidos integralmente;
                                        As reservas podem ser canceladas e alteradas até 3h antes do embarque.
                                    </div>
                                </div>
                                <div id='informativo3' className='row informativos'>
                                    <span id='informativoIcons' className="fas fa-user-alt-slash"></span>
                                    <h3 id='tituloInformativos'><b>Ausência no embarque</b></h3>
                                    <div className='informativoText'>
                                        Em caso de não comparecimento a {reserva.nomeEmpresa} disponibiliza um cupom para remarcação de
                                        80% do valor pago na reserva.
                                    </div>
                                </div>
                                <div id='informativo4' className='row informativos'>
                                    <span id='informativoIcons' className="far fa-address-card"></span>
                                    <h3 id='tituloInformativos'><b>Documentos para Viajar</b></h3>
                                    <div className='informativoText'>
                                        Para embarcar você deve levar apenas o RG ou CNH ou Passaporte ou Carteira de Trabalho.
                                        O documento deve ser original e estar legível.
                                        Você precisa retirar a passagem na {reserva.embarque}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    render() {
        return (

            <div className="container">
                <h2 className='text-center titulo-reservas'>Minhas reservas</h2>
                <div>
                    <div className='col-md-12 d-flex justify-content-center mb-2'>
                        <button className="btn btn-laranja ml-4" onClick={() => this.props.history.push('meus-pedidos')}>Voltar
                        </button>
                    </div>
                    {this.exibirTabelaReservas()}
                    {this.exibirMapaEmbarqueDesembarque()}
                </div>
            </div>
        )
    }
}


export const mapStateToProps = state => {
    return {
        pedido: state.pedido.pedidoSelecionado
    }
};


export default connect(mapStateToProps,
    {

    })(PaginaMinhasReservas)

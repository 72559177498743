import React, { Component } from 'react'

import 'react-datepicker/dist/react-datepicker.css';
import capitalizarTodasPrimeirasLetras from '../../util/capitalizarTodasPrimeirasLetras';
import moment from 'moment'
import { selecionarReserva, cancelarReserva } from '../../actions/reservasActions';
import { buscarVoucher } from '../../actions/pedidosActions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import pt from 'date-fns/locale/pt';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { mascaraCPF } from '../../util/arraysUtil';
import { showSuccessMsg } from '../../util/messagesUtil';

registerLocale('pt', pt);
setDefaultLocale('pt');

const ModalConfirmacaoExclusao = ({ reserva, cancelarCompra, limparReservaSelecionada }) => (
    <div className="modal fade" id="modalConfirmacaoExcluirReserva" tabIndex="-1" role="dialog"
        aria-labelledby="modalConfirmacaoExcluirReserva" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-body texto-centralizado">
                    <p><strong>{`Deseja excluir a reserva de ${ reserva ? reserva.passageiroDto.nome : ''}`}</strong></p>
                </div>
                <div className="modal-footer ">
                    <button id='cancelar' type="button" className="btn btn-outline-secondary"
                        data-dismiss="modal"
                        onClick={() => limparReservaSelecionada(null)}>Cancelar
                    </button>
                    <button type="button" className="btn btn-laranja float-left" data-dismiss="modal"
                        onClick={() => cancelarCompra()}>Confirmar
                    </button>
                </div>
            </div>
        </div>
    </div>);
export class MinhaReserva extends Component {
    constructor(props) {
        super(props);        
        this.state = { voucher: null }
    }
    selecionarReserva(reserva) {
        this.props.selecionarReserva(reserva);
        this.props.history.push('/recibo-reserva')
    }

    montarStilo(reserva) {
        return reserva.situacaoReserva === 'APROVADO' ? 'item-passagem' : ''
    }

    cancelarCompra() {        
        const { pedido } = this.props;
        const comandoCancelarCompra = {
            empresaId: pedido.idEmpresa,
            reservas: [this.props.reservaSelecionada.id],
            motivo: 'Cancelamento de compra pelo cliente'
        }
        this.props.cancelarReserva(comandoCancelarCompra).then(() => {
            showSuccessMsg('Solicitação de cancelamento enviada com sucesso. Em instantes à situação do pedido será atualizada');
            this.props.history.push('/meus-pedidos');
        });
    }
        
    downloadPdf = (reserva) => {
        if (reserva.ehItegracao) {
            this.props.buscarVoucher(reserva.id).then(response => {
                if (response) {
                    // Converte a string base64 em um Blob
                    const {base64, ehBPe } =  response.payload.data
                    const byteCharacters = atob(base64);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    // eslint-disable-next-line no-undef
                    const byteArray = new Uint8Array(byteNumbers);
                    let blob;
                    if (ehBPe
                    ) {
                        blob = new Blob([byteArray], { type: 'application/pdf' });
                    } else {
                        blob = new Blob([byteArray], { type: 'image/png' });
                    }
                    // Cria um link temporário e inicia o download
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);

                    link.download = ehBPe ? `BPe_${reserva.id}.pdf` : `Voucher_${reserva.id}.png`;
                    link.click();
                }
            }
            )
        }


    }

    render() {
        const { reserva } = this.props;
        const dataPartida = moment(reserva.dataHoraPartida).format('DD/MM/YYYY HH:mm');
        return (
            <div className="card-group mt-2">
                <div className='card col-sm-12 row align-content-center item-passagem' style={{ margin: 0 }}>
                    <div className="card-body ml-2 align-content-center card-reserva col-sm-12">
                        <div className="row">
                            <div className="col-sm-6">
                                <label>Número do bilhete:</label>
                                <p>{reserva.id}</p>
                            </div>
                            <div className="col-sm-2">
                                <label>Número da Poltrona:</label>
                                <p>{reserva.numeroPoltrona}</p>
                            </div>
                            {reserva.situacaoReserva === 'APROVADO' || reserva.situacaoReserva === 'CANCELADO' ?
                                <div className="col-sm-4 imprimir">
                                    {reserva.ehItegracao ?
                                        <button type='button' className="btn-bilhete" onClick={() => this.selecionarReserva(reserva)}>
                                            <i className="material-icons">&#xe8b0;</i><a className='detalhe'>Dados da Venda</a>
                                        </button>
                                        :
                                        <button type='button' className="btn-bilhete" onClick={() => this.selecionarReserva(reserva)}>
                                            <i className="material-icons">&#xe8b0;</i><a className='detalhe'>Imprimir Bilhete</a>
                                        </button>
                                    }
                                </div> : ''}
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <label>Passageiro:</label>
                                <p>{capitalizarTodasPrimeirasLetras(reserva.passageiroDto.nome)}</p>
                            </div>
                            <div className="col-sm-2">
                                <label>CPF:</label>
                                <p>{mascaraCPF(reserva.passageiroDto.cpfPassageiro)}</p>
                            </div>
                            {reserva.situacaoReserva === 'APROVADO' ?
                                <div className="col-sm-4 imprimir">
                                    <button type='button' className="btn-bilhete" onClick={() => this.downloadPdf(reserva)}>
                                        <i className="material-icons">&#xe8b0;</i><a className='detalhe'>Cupom/Voucher</a>
                                    </button>
                                </div> : ''}
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <label>Data/hora partida:</label>
                                <p>{dataPartida}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <label>Origem:</label>
                                <p>{reserva.descCidadeOrigem}</p>
                            </div>
                            <div className="col-sm-6">
                                <label>Ponto de Embarque:*</label>
                                <p>{reserva.embarque}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <label>Destino:</label>
                                <p>{reserva.descCidadeDestino}</p>
                            </div>
                            <div className="col-sm-6">
                                <label>Ponto de Desembarque:*</label>
                                <p>{reserva.desembarque}</p>
                            </div>
                        </div>
                        <div className="row valor-situacao-reserva">
                            <div className="col-sm-6">
                                <label>Situação:</label>
                                <p>{capitalizarTodasPrimeirasLetras(reserva.situacaoReserva)}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <label>***Verifique no final da página o seu local de embarque e desembarque.</label>
                            </div>
                            {reserva.situacaoReserva === 'APROVADO' ?
                                <div className="col-sm-4 text-center float-right" data-toggle="modal"
                                    data-target="#modalConfirmacaoExcluirReserva"
                                    style={{ borderRadius: 10, cursor: 'pointer' }}
                                    onClick={() => {                                        
                                        this.props.selecionarReserva(reserva);
                                    }}>
                                    <button type='button' className='btn-bilhete p-2 mb-1 float-right'>
                                        CANCELAR RESERVA
                                    </button>
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
                <ModalConfirmacaoExclusao reserva={this.props.reservaSelecionada} cancelarCompra={this.cancelarCompra.bind(this)} limparReservaSelecionada={this.selecionarReserva.bind(this)} />
            </div>
        )
    }
}


export const mapStateToProps = state => {
    return {
        reservaSelecionada: state.reserva.reservaSelecionada
    }
};

export default withRouter(connect(mapStateToProps, { selecionarReserva, cancelarReserva, buscarVoucher })(MinhaReserva));
